import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography,
  Select,
  Tooltip,
} from "@mui/material";
import { ReactComponent as Search } from "../../../assets/svg/Search.svg";
import DataGridListing from "../../../components/listing/DataGridListing";
import { ReactComponent as Edit } from "../../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";
import { ReactComponent as Filter } from "../../../assets/svg/Filter.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useState, useMemo } from "react";
import { get, destroy, put } from "../../../server";
import {
  ADMIN_COUSTOMER_LISTING,
  ADMIN_COUSTOMER_DELETE,
  ADMIN_COUSTOMER_CHANGE_STATUS,
} from "../../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMutation, useQuery, useQueryClient } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import routes from "../../../data/constants/routes";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import moment from "moment";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BoxModal from "../../../components/modal/BoxModal";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { Clear } from "@mui/icons-material";
import PageTitle from "../../../components/common/PageTitle";

const styles = {
  topStack: {
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "15px",
    "@media (max-width: 950px)": {
      flexWrap: "wrap",
    },
  },
  title: {
    fontSize: { md: 22, xs: 18 },
    fontWeight: 600,
    color: "#1E1E1F",
    flex: 1,
    textTransform: "capitalize",
  },
  searchInput: {
    maxWidth: 340,
    width: "100%",
    "& .MuiOutlinedInput-root": {
      px: "21px",
      backgroundColor: "#fff",
      minHeight: 35,
      borderRadius: "10px",
      "& fieldset": {
        border: "1px solid #EEE",
      },
      "&:hover fieldset": {
        borderColor: "#EEE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#656DFC",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #AEAEAE",
      borderRadius: "10px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px !important",
    },
    "& input": {
      backgroundColor: "#fff",
      height: "35px",
      borderRadius: "10px",
      fontWeight: 500,
      "&::placeholder": {
        color: "#B4B1B6",
        fontWeight: 400,
        opacity: 1,
        fontSize: 16,
      },
    },

    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
  },

  exportBtn: {
    textTransform: "capitalize",
    bgcolor: "#E8EEF5",
    color: "#1E1E1F",
    fontSize: 16,
    fontWeight: 600,
    width: 150,
    boxShadow: 0,
    borderRadius: "10px",
    "&:hover": {
      bgcolor: "#E8EEF5",
      color: "#1E1E1F",
    },
  },
  filterBtn: {
    textTransform: "capitalize",
    bgcolor: "#135099",
    color: "#FFF",
    height: "50px",
    fontSize: 16,
    fontWeight: 600,
    width: 150,
    boxShadow: 0,
    borderRadius: "10px",
    "&:hover": {
      bgcolor: "#135099",
      color: "#FFF",
    },
    "& svg": {
      mr: 1,
    },
  },
  filterContainer: {
    mt: "20px",
    position: "relative",
    bgcolor: "#FFF",
    pt: 3,
    pl: "27px",
    pb: "30px",
    pr: "53px",
    borderRadius: "20px",
    mb: "20px",
  },
  typesContainer: {
    bgcolor: "#F9FBFC",
    width: 280,
    px: 2,
    py: 1,
    borderRadius: "24px",
    mt: "14px",
    "& legend": {
      color: "#1E1E1F",
      textAlign: "center",
      fontSize: 18,
      py: 1,
      px: "20px",
      cursor: "pointer",
      borderRadius: "50px",
      width: "100%",
    },
  },
  activeType: {
    color: "#135099",
    bgcolor: "#FFF",
  },
  filterSerach: {
    mt: "20px",
    "& .MuiInputBase-root": {
      height: 54,
      borderRadius: "10px",
    },
  },
  selectInput: {
    maxWidth: 340,
    border: 0,
    "& .MuiSelect-select": {
      border: 0,
      borderColor: "red",
      backgroundColor: "transparent",
      fontWeight: 400,
      fontSize: 16,
      color: "#202020",
      "& .MuiOutlinedInput-notchedOutline": {
        // border: "1px solid red !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #000",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px",
    },
    "& span": {
      color: "#9c9c9c",
    },
  },
  exportMenu: {
    mt: "60px",
    "& .MuiPaper-root": {
      width: 200,
      borderRadius: "10px",
      boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.20)",
      color: "#313131",
      lineHeight: "26.53px",
    },
    "& .MuiList-root": {
      pt: "6px",
      pb: 0,
    },
  },
  dropDownMenu: {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      width: 200,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },
};

const CustomersListing = () => {
  const [customers, setCustomers] = useState([]);
  const [id, setId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [filterText, setFilterText] = useState("");

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const filteredItems = useMemo(() => {
    const filtered = customers.filter((item) =>
      `${item.name || ""} ${item.email || ""}`
        .toLowerCase()
        .includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, customers]);

  const fetchCustomers = async () => {
    let { status, message, data } = await get(ADMIN_COUSTOMER_LISTING);
    if (status) {
      setCustomers(data);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const allCustomersQuery = useQuery(queryNames.CUSTOMER, fetchCustomers, {
    refetchOnWindowFocus: false,
  });

  const handleOpenDeleteModal = (id) => {
    setShowModal("delete");
    setId(id);
  };

  const handleStatusChange = (id, status) => {
    setShowModal(status);
    setId(id);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setId(null);
  };

  const client = useQueryClient();

  const handleDelete = async () => {
    const { status, message } = await destroy(
      `${ADMIN_COUSTOMER_DELETE}/${id}`
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });

      handleCloseModal();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleDelete, {
    onSuccess: () => {
      client.invalidateQueries([queryNames.CUSTOMER]);
    },
  });

  const changeStatus = async () => {
    const { status, message } = await put(
      `${ADMIN_COUSTOMER_CHANGE_STATUS}/${id}`,
      {
        status: showModal,
      }
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });

      handleCloseModal();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const statusMutation = useMutation(changeStatus, {
    onSuccess: () => {
      client.invalidateQueries([queryNames.CUSTOMER]);
    },
  });

  const handleConfirm = () => {
    if (showModal === "delete") mutation.mutate();
    else statusMutation.mutate();
  };

  const columns = [
    {
      field: "name",
      headerName: "Member Name",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            <Avatar
              // src={params.row.image}
              sx={{ mr: 1.5, width: 40, height: 40 }}
            >
              {params.row.first_name.charAt(0)}
            </Avatar>
            {`${params.row.first_name} ${params.row.last_name}`}
          </>
        );
      },
    },

    {
      field: "email",
      headerName: "Email",
      sortable: false,
      flex: 1,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        moment(params.row.updated_at).format("D MMM YYYY, h:mm:ss A"),
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        params.row.status === "active" ? "Active" : "Inactive",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" alignItems="center" gap={1}>
              <Tooltip title="Edit" placement="top">
                <IconButton
                  disableRipple
                  onClick={() =>
                    navigate(`${routes.ADMIN_CUSTOMERS_EDIT}/${params.row.id}`)
                  }
                  size="small"
                >
                  <Edit />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" placement="top">
                <IconButton
                  disableRipple
                  onClick={() => handleOpenDeleteModal(params.row.id)}
                  size="small"
                >
                  <Delete />
                </IconButton>
              </Tooltip>

              {params.row.status === "active" ? (
                <Tooltip title="Deactivate" placement="top">
                  <IconButton
                    disableRipple
                    onClick={() =>
                      handleStatusChange(params.row.id, "inactive")
                    }
                    size="small"
                  >
                    <ToggleOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Activate" placement="top">
                  <IconButton
                    disableRipple
                    onClick={() => handleStatusChange(params.row.id, "active")}
                    size="small"
                  >
                    <ToggleOnIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </>
        );
      },
    },
  ];

  if (allCustomersQuery.isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Stack direction="row" sx={styles.topStack} mb={2}>
        <Typography component="h4" sx={styles.title} flex={1}>
          Customers
        </Typography>

        <FormControl
          variant="outlined"
          value={filterText}
          onChange={handleFilter}
          sx={{ ...styles.searchInput, ...styles.topSearch }}
        >
          <OutlinedInput
            size="small"
            type="text"
            value={filterText}
            endAdornment={
              <InputAdornment position="end">
                {filterText.length > 0 ? (
                  <Tooltip title="Clear Search">
                    <IconButton onClick={() => setFilterText("")}>
                      <Clear />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Search />
                )}
              </InputAdornment>
            }
            placeholder="Search..."
          />
        </FormControl>

        <Stack direction="row" gap="15px">
          <Button
            variant="contained"
            sx={styles.filterBtn}
            onClick={() => navigate(routes.ADMIN_CUSTOMERS_ADD)}
          >
            Add
          </Button>
        </Stack>
      </Stack>

      <DataGridListing rows={filteredItems} columns={columns} mode="client" />
      <BoxModal
        header="Are you sure?"
        title={
          showModal === "delete"
            ? "Do you really want to delete this record. This process cannot be undone."
            : showModal === "active"
            ? "Do you really want to activate this record."
            : "Do you really want to deactivate this record."
        }
        open={showModal === "active" ? "confirm" : showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirm}
        confirmButton={showModal === "delete" ? "Delete" : "Confirm"}
        confirmButtonColor="#E53935"
        isLoading={mutation.isLoading}
      />
    </>
  );
};

export default CustomersListing;
