import {
  Stack,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import React from "react";
import { Clear } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Search } from "../../assets/svg/Search.svg";
import useFeature from "../../hooks/useFeature";
import { SUBSCRIPTION_KEY } from "../../data/constants/feature";

const styles = {
  wrapper: {
    flexDirection: { md: "row", xs: "column" },
    gap: 2,
    justifyContent: "space-between",
    alignItems: "center",
    textWrap: "nowrap",
    py: 2,
    "& h1": {
      fontSize: { md: 22, xs: 18 },
      fontWeight: 600,
      color: "#1E1E1F",
      flex: 1,
      // textTransform: "capitalize",
    },
  },
  searchInput: {
    maxWidth: 340,
    width: "100%",
    "& .MuiOutlinedInput-root": {
      px: "21px",
      backgroundColor: "#fff",
      minHeight: 35,
      borderRadius: "10px",
      "& fieldset": {
        border: "1px solid #EEE",
      },
      "&:hover fieldset": {
        borderColor: "#EEE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#656DFC",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #AEAEAE",
      borderRadius: "10px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px !important",
    },
    "& input": {
      backgroundColor: "#fff",
      height: "35px",
      borderRadius: "10px",
      fontWeight: 500,
      "&::placeholder": {
        color: "#B4B1B6",
        fontWeight: 400,
        opacity: 1,
        fontSize: 16,
      },
    },

    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
  },

  button: {
    minWidth: 150,
    px: 3,
    textTransform: "capitalize",
    bgcolor: "#135099",
    color: "#FFF",
    height: "50px",
    fontWeight: 600,
    width: 150,
    boxShadow: 0,
    borderRadius: "10px",
    "&:hover": {
      bgcolor: "#135099",
      color: "#FFF",
    },
    "& svg": {
      mr: 1,
    },
  },
  searchBtn: {
    width: 40,
    height: 40,

    "& svg > path": {
      fill: "#135099",
    },
  },
  filledSearchBtn: {
    bgcolor: "#135099",
    width: 40,
    height: 40,
    "&:hover": {
      bgcolor: "#135099",
    },
    "& svg > path": {
      fill: "#fff",
    },
  },
};

const PageTitle = ({
  title,
  placeholder,
  searchInput = false,
  search,
  setSearch,
  handleSearchChange,
  isSearch,
  handleClearSearch,
  handleSearchClick,
  createBtn,
  createBtnText,
  url,
  isCreateProductBtn = false,
  isHandleChangeSearch = false,
}) => {
  const { compareLimits, updateCount } = useFeature();

  const navigate = useNavigate();
  const handleCheckAccess = (path, type) => {
    let res = compareLimits(type);
    if (res) {
      navigate(path);
    } else {
      return false;
    }
  };

  return (
    <Stack sx={styles.wrapper}>
      <Typography component="h1">{title}</Typography>
      {/* {search && isSearch && (
        <IconButton disableRipple onClick={handleCancleSearch}>
          <Clear />
        </IconButton>
      )} */}

      {searchInput ? (
        <FormControl variant="outlined" sx={styles.searchInput}>
          <OutlinedInput
            size="small"
            type="text"
            value={search}
            onChange={handleSearchChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearchClick();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                {search && isSearch ? (
                  <Tooltip title="Clear Search" placement="top">
                    <IconButton onClick={handleClearSearch}>
                      <Clear />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Search" placement="top">
                    <IconButton
                      onClick={handleSearchClick}
                      sx={
                        isHandleChangeSearch
                          ? styles.filledSearchBtn
                          : styles.searchBtn
                      }
                    >
                      <Search />
                    </IconButton>
                  </Tooltip>
                )}
              </InputAdornment>
            }
            placeholder={placeholder}
          />
        </FormControl>
      ) : null}

      {isCreateProductBtn ? (
        <Button
          variant="contained"
          onClick={() => handleCheckAccess(url, SUBSCRIPTION_KEY.commerce)}
          sx={[styles.button, { fontSize: isCreateProductBtn ? 30 : 16 }]}
        >
          {createBtnText}
        </Button>
      ) : createBtn ? (
        <Button
          variant="contained"
          onClick={() => navigate(url)}
          sx={[styles.button, { fontSize: 16 }]}
        >
          {createBtnText}
        </Button>
      ) : null}
    </Stack>
  );
};

export default PageTitle;
