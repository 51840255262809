import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import Listing from "../../../components/listing/DataGridListing";
import { ReactComponent as Edit } from "../../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";
import Overlay from "../../../components/overlay/Overlay";
import PageTitle from "../../../components/common/PageTitle";
import FormTab from "../../../components/tabs/FormTab";
import { useState } from "react";
import TabPanel from "../../../components/tabs/TabPanel";
import PlaceholderImage from "../../../assets/images/PlaceholderImage.jpg";
import { useQuery } from "react-query";
import { get } from "../../../server";
import { useSnackbar } from "notistack";
import queryNames from "../../../data/constants/queryNames";
import { useNavigate } from "react-router-dom";
import routes from "../../../data/constants/routes";
import { CUSTOMER_ALL_ORDERS } from "../../../data/constants/apiRoutes";
import Pagination from "../../../components/pagination/Pagination";
import Loader from "../../../components/Loader";
import moment from "moment";
import { Clear } from "@mui/icons-material";
import FormInput from "../../../components/forms/FormInput";
import SearchIcon from "@mui/icons-material/Search";

const styles = {
  topWrapper: {
    flexDirection: { sm: "row", xs: "column" },
    alignItems: { sm: "center", xs: "flex-start" },
    justifyContent: "space-between",
    gap: 1.5,
  },

  cell: {
    border: "1px solid #E6E6E9",
    p: 2,
    bgcolor: "#fff",
    borderRadius: 4,
    cursor: "pointer",
    mb: 2,
    "& span": {
      textTransform: "capitalize",
    },
  },
  teamName: {
    textTransform: "capitalize",
    fontSize: 18,
    fontWeight: 500,
  },

  title: {
    color: "#000",
    fontSize: 18,
    fontWeight: 500,
    whiteSpace: "nowrap",
    mr: 0.5,
  },

  teamImage: {
    width: 50,
    height: 50,
    borderRadius: 50,
    border: "1px solid #E6E6E9",
  },

  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    mt: 5,
    "& h1": {
      fontSize: 24,
      color: "#000",
      fontWeight: 500,
    },
  },

  wrapper: { borderTop: "1px solid #EBEBEE", marginTop: "20px", py: "20px" },
  searchBtn: {
    width: 40,
    height: 40,

    "& svg > path": {
      fill: "#135099",
    },
  },
  filledSearchBtn: {
    bgcolor: "#135099",
    width: 40,
    height: 40,
    "&:hover": {
      bgcolor: "#135099",
    },
    "& svg > path": {
      fill: "#fff",
    },
  },
};

const CardOrdersListing = () => {
  const [tabValue, setTabValue] = useState(0);
  const [orders, setOrders] = useState([]);

  const [filterText, setFilterText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [isHandleChangeSearch, setIsHandleChangeSearch] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [totalCount, setTotalCount] = useState(0);

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setIsHandleChangeSearch(true);
    if (e.target.value.length < 1) {
      setIsHandleChangeSearch(false);
    }
  };

  const handleSearch = () => {
    if (filterText.length > 0) {
      setIsSearch(true);
      setSearchParam(filterText);
    }
  };

  const handleCancleSearch = () => {
    setIsSearch(false);
    setSearchParam("");
    setFilterText("");
  };

  const fetchOrders = async () => {
    let url;

    url = new URL(CUSTOMER_ALL_ORDERS);
    url.searchParams.append("page", paginationModel.page + 1);
    url.searchParams.append("limit", paginationModel.pageSize);

    if (tabValue === 0) {
      url.searchParams.append("filterBy", "onGoing");
    } else {
      url.searchParams.append("filterBy", "history");
    }
    if (isSearch) {
      url.searchParams.append("search", searchParam);
    }

    const { status, message, data } = await get(url.toString());
    if (status) {
      setOrders(data.data);
      setTotalCount(data.totalCount);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const ordersQuery = useQuery(
    [queryNames.ORDER, paginationModel.page, tabValue, searchParam],
    fetchOrders,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (ordersQuery.isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Stack sx={styles.topWrapper}>
        <PageTitle title="Card Orders" />
        <Stack direction="row" alignItems="center" spacing={2}>
          {isSearch && (
            <IconButton onClick={handleCancleSearch}>
              <Clear />
            </IconButton>
          )}
          <FormInput
            placeholder="Search..."
            value={filterText}
            onChange={handleFilter}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleSearch}
                    sx={
                      isHandleChangeSearch
                        ? styles.filledSearchBtn
                        : styles.searchBtn
                    }
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSearch();
            }}
          />
        </Stack>
      </Stack>
      <Grid container spacing={2} mt={2}>
        <Box>
          <FormTab
            tabValue={tabValue}
            setTabValue={setTabValue}
            tabs={["Ongoing", "History"]}
            centered
          />
        </Box>
      </Grid>
      <Box sx={styles.wrapper}>
        {orders.length > 0 ? (
          <>
            {orders.map((order) => {
              return (
                <Box
                  key={order.id}
                  sx={styles.cell}
                  onClick={() =>
                    navigate(
                      `${routes.CUSTOMER_CARD_ORDER_HISTORY}/${order.id}`
                    )
                  }
                >
                  {order?.team ? (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap="wrap"
                    >
                      <Stack direction="row" alignItems="center" gap={1.5}>
                        {order?.team?.logo ? (
                          <Box
                            component="img"
                            src={order?.team?.logo}
                            sx={styles.teamImage}
                            alt={order?.team?.name}
                          />
                        ) : (
                          <Box
                            component="img"
                            src={PlaceholderImage}
                            sx={styles.teamImage}
                            alt={order?.team?.name}
                          />
                        )}

                        <Typography sx={styles.teamName}>
                          {order?.team?.name}
                        </Typography>
                      </Stack>
                      <Typography>
                        <Typography component="span" sx={styles.title}>
                          Order Id:
                        </Typography>
                        <Typography component="span">
                          {order.orderId}
                        </Typography>
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap="wrap"
                    >
                      <Stack direction="row" alignItems="center" gap={1.5}>
                        {order?.orderDetail?.[0]?.card?.profileImage ? (
                          <Box
                            component="img"
                            src={order?.orderDetail?.[0]?.card?.profileImage}
                            sx={styles.teamImage}
                            alt={`${order?.user?.first_name} ${order?.user?.last_name}`}
                          />
                        ) : (
                          <Box
                            component="img"
                            src={PlaceholderImage}
                            sx={styles.teamImage}
                            alt={`${order?.user?.first_name} ${order?.user?.last_name}`}
                          />
                        )}

                        <Typography sx={styles.teamName}>
                          {`${order?.user?.first_name} ${order?.user?.last_name}`}
                        </Typography>
                      </Stack>
                      <Typography>
                        <Typography component="span" sx={styles.title}>
                          Order Id:
                        </Typography>
                        <Typography component="span">
                          {order.orderId}
                        </Typography>
                      </Typography>
                    </Stack>
                  )}
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                    flexWrap="wrap"
                  >
                    <Typography>
                      <Typography component="span" sx={styles.title}>
                        Status:
                      </Typography>
                      <Typography component="span" fontSize={18}>
                        {order.status}
                      </Typography>
                    </Typography>
                    <Typography>
                      <Typography component="span" sx={styles.title}>
                        Date:
                      </Typography>
                      <Typography component="span">
                        {moment(order.created_at).format("DD/MMM/YYYY")}
                      </Typography>
                    </Typography>
                  </Stack>
                </Box>
              );
            })}
          </>
        ) : (
          <Box sx={styles.noData}>
            <Typography component="h1">No record found</Typography>
          </Box>
        )}
      </Box>
      {orders.length > 0 && (
        <Pagination
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          totalCount={totalCount}
        />
      )}

      {/* <Listing rows={rows} columns={columns} mode="client" /> */}
    </>
  );
};

export default CardOrdersListing;
