import { Box } from "@mui/material";

const InstagramPlayer = ({ videoId }) => {
	return (
		<Box sx={{ height: "700px !important" }}>
			<iframe
				src={`https://www.instagram.com/reel/${videoId}/embed/`}
				allowFullScreen
				title="Instagram Video Player"
				width="100%"
				height={700}
				style={{ border: "none", overflow: "hidden" }}
			/>
		</Box>
	);
};

export default InstagramPlayer;
