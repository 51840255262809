import YouTubePlayer from "./YouTubePlayer";
import VimeoPlayer from "./VimeoPlayer";
import TikTokPlayer from "./TikTokPlayer";
import IgPlayer from "./IgPlayer";

import { Box, Modal } from "@mui/material";

const styles = {
	videoModal: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		// height: "100%",
	},
};

const VideoModal = ({ modal, setModal, videoId }) => {

	const renderPlayer = () => {
	
		switch (modal) {
			case "youtube":
				return <YouTubePlayer videoId={videoId} />;
			case "vimeo":
				return <VimeoPlayer videoId={videoId} />;
			case "tiktok":
				return <TikTokPlayer videoId={videoId} />;
			case "ig":
					return <IgPlayer videoId={videoId} />;
			default:
				return null;
		}
	};

	return (
		<Modal open={modal} onClose={() => setModal(false)}>
			<Box
				sx={{
					...styles.videoModal,
					"& iframe": {
						height: modal === "tiktok" ? "740px !important" : "revert-layer !important",
					},
				}}
			>
				{renderPlayer()}
			</Box>
		</Modal>
	);
};

export default VideoModal;
