import Listing from "../../../components/listing/DataGridListing";
import { ReactComponent as Edit } from "../../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";
import PageTitle from "../../../components/common/PageTitle";
import routes from "../../../data/constants/routes";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import {
  PRODUCT_DELETE,
  PRODUCT_LISTING,
} from "../../../data/constants/apiRoutes";
import { get, destroy } from "../../../server";
import queryNames from "../../../data/constants/queryNames";
import { useNavigate } from "react-router-dom";
import BoxModal from "../../../components/modal/BoxModal";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import Overlay from "../../../components/overlay/Overlay";

const ProductListing = () => {
  const [modal, setModal] = useState(false);
  const [product, setProduct] = useState();
  const [search, setSearch] = useState("");
  const [searchedValue, setSearchedValue] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [isHandleChangeSearch, setIsHandleChangeSearch] = useState(false);

  const navigate = useNavigate();
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setIsHandleChangeSearch(true);
    if (e.target.value.length < 1) {
      setIsHandleChangeSearch(false);
      setIsSearch(false);
      setSearchedValue("");
    }
  };

  const handleSearchClick = () => {
    setIsSearch(true);
    setSearchedValue(search);
  };

  const handleClearSearch = () => {
    setIsSearch(false);
    setSearch("");
    setSearchedValue("");
  };

  const columns = [
    {
      field: "name",
      headerName: "Product Name",
      flex: 1,
    },
    {
      field: "brand",
      headerName: "Brand",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      valueGetter: (params) => "$" + params.row.price,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Typography textTransform="capitalize">{params.row.status}</Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0.5}>
            <IconButton
              onClick={() =>
                navigate(`${routes.CUSTOMER_PRODUCT_EDIT}/${params.row.id}`)
              }
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                setProduct(params.row);
                setModal(true);
              }}
            >
              <Delete />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const fetchProducts = async (pageNo, limit, searchParam) => {
    let url = new URL(PRODUCT_LISTING);
    if (searchParam.length) {
      url.searchParams.append("search", searchParam);
    }
    url.searchParams.append("page", pageNo + 1);
    url.searchParams.append("limit", limit);

    return await get(url.toString());
  };

  const handleInvalidate = () => {
    client.invalidateQueries(queryNames.PRODUCTS);
  };

  const handleClose = () => {
    setModal(false);
    setProduct(null);
    handleInvalidate();
  };

  const handleDelete = async () => {
    const { status, message } = await destroy(
      `${PRODUCT_DELETE}/${product?.id}`
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      handleClose();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleDelete, {
    onSuccess: () => {
      client.invalidateQueries([queryNames]);
    },
  });

  const handleConfirm = (e) => {
    e.preventDefault();
    mutation.mutate();
  };

  return (
    <>
      <PageTitle
        title="My eCommerce"
        searchInput
        placeholder="Search Products"
        search={search}
        setSearch={setSearch}
        isSearch={isSearch}
        handleClearSearch={handleClearSearch}
        handleSearchClick={handleSearchClick}
        handleSearchChange={handleSearchChange}
        createBtn
        createBtnText="+"
        isCreateProductBtn={true}
        url={routes.CUSTOMER_PRODUCT_ADD}
        isHandleChangeSearch={isHandleChangeSearch}
      />

      <Listing
        columns={columns}
        fetchRecords={fetchProducts}
        queryName={queryNames.PRODUCTS}
        search={searchedValue}
      />

      <BoxModal
        header="Are you sure?"
        title={`Do you really want to delete ${product?.name}?`}
        open={modal}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        isLoading={mutation.isLoading}
      />
    </>
  );
};

export default ProductListing;
