import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Stack,
  InputAdornment,
  IconButton,
  Button,
  Typography,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import PageTitle from "../../../components/common/PageTitle";

import { useSnackbar } from "notistack";
import FormInput from "../../../components/forms/FormInput";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import routes from "../../../data/constants/routes";
import AddBusinessCard from "../../../components/customer/AddBusinessCard";
import { styled } from "@mui/material/styles";
import { ReactComponent as PlaceholderImage } from "../../../assets/svg/createCard/PlaceholderImage.svg";

import RightBusinessDrawer from "../../../components/customer/RightBusinessDrawer";
import {
  SAVE_CARD,
  COMMUNITY_LISTING,
  COMMUNITY_DELETE,
  ALL_COMMUNITY_LISTING,
} from "../../../data/constants/apiRoutes";
import { destroy, get, post } from "../../../server";
import { Clear, MoreVertOutlined } from "@mui/icons-material";
import Loader from "../../../components/Loader";
import { useAtom } from "jotai";
import { mobileViewAtom } from "../../../data/store";
import { useQuery, useQueryClient } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import QrCodeModel from "../../../components/modal/QrCodeModel";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import Pagination from "../../../components/pagination/Pagination";
import LoadingBackDrop from "../../../components/overlay/LoadingBackDrop";
import ExcelJS from "exceljs";
import { formatUrl, formatPhoneNumber } from "../../../utils/helpers/formatter";

const styles = {
  topWrapper: {
    flexDirection: { sm: "row", xs: "column" },
    alignItems: { sm: "center", xs: "flex-start" },
    justifyContent: "space-between",
    gap: 1.5,
    pb: 2,
  },
  addBtn: {
    whiteSpace: "nowrap",
    background: "#135099",
    borderRadius: "10px",
    textTransform: "capitalize",
    width: "200px",
    height: "50px",
    "&:hover": {
      background: "#135099",
    },
  },
  gridWrapper: {
    display: "flex",
    justifyContent: { sm: "flex-start", xs: "center" },
    flexWrap: "wrap",
    gap: "30px",
    pt: 2,
  },
  card: {
    position: "relative",
    background: "#fff",
    borderRadius: 4,
    width: 200,
    height: 190,
    "& h3": {
      color: "#000",
      fontSize: 18,
      fontWeight: 500,
      textAlign: "center",
      mt: 1,
      px: 1,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
    },
    "& p": {
      color: "#4B4B4B",
      textAlign: "center",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
      px: 1,
    },
  },
  menuBtn: {
    position: "absolute",
    top: 15,
    right: 15,
    p: "5px",
    background: "rgba(255, 255, 255, 0.3)",
    backdropFilter: "blur(20px)",
    "& svg": {
      width: 20,
      height: 20,
    },
  },
  coverImgWrapper: {
    height: 140,
    borderRadius: "16px 16px 0px 0px",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "16px 16px 0px 0px",
    },
  },
  coverImgSkeleton: {
    height: 140,
    borderRadius: "16px 16px 0px 0px",
    bgcolor: "#eee",
  },
  profileWrapper: {
    textAlign: "center",
    mt: 3,
    "& img": {
      //   width: "100%",
      //   height: "100%",
      width: 70,
      height: 70,
      objectFit: "cover",
      border: "2px solid #fff",
      borderRadius: "50%",
    },
    "& svg": {
      width: "100%",
      height: 70,
    },
  },
  deleteModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    p: 4,
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // minHeight: "calc(100vh - 64px)",

    // width: "100%",
    mt: 5,
    "& h1": {
      fontSize: 24,
      color: "#000",
      fontWeight: 500,
    },
  },
  searchBtn: {
    width: 40,
    height: 40,

    "& svg > path": {
      fill: "#135099",
    },
  },
  filledSearchBtn: {
    bgcolor: "#135099",
    width: 40,
    height: 40,
    "&:hover": {
      bgcolor: "#135099",
    },
    "& svg > path": {
      fill: "#fff",
    },
  },
  exportBtn: {
    minWidth: 120,
    px: 3,
    textTransform: "capitalize",
    bgcolor: "#135099",
    color: "#FFF",
    height: "50px",
    fontWeight: 600,
    boxShadow: 0,
    borderRadius: "10px",
    "&:hover": {
      bgcolor: "#135099",
      color: "#FFF",
    },
  },
};

const drawerWidth = 350;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, mobileView }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: mobileView ? 0 : -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const ConnectionsListing = () => {
  const [open, setOpen] = useState(false);
  const [cards, setCards] = useState([]);
  const [currentCard, setCurrentCard] = useState({});
  const [contactId, setContactId] = useState(null);

  const [openQrModal, setOpenQrModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [filterText, setFilterText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [isHandleChangeSearch, setIsHandleChangeSearch] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const client = useQueryClient();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const [mobileView, setMobileView] = useAtom(mobileViewAtom);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [totalCount, setTotalCount] = useState(0);

  const handleClick = (e, card) => {
    setCurrentCard(card?.contactCard);
    setContactId(card?.id);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    // setCurrentCard({});
    setAnchorEl(null);
  };

  const handleDrawerOpen = (card) => {
    setOpen(true);
    setAnchorEl(null);
  };

  // const handleEditNavigation = () => {
  // 	navigate(`${routes.CUSTOMER_EDIT_CONNECTION}/${currentCard.id}`);
  // };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenDelete = () => {
    handleClose();
    setOpenDeleteModal(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteModal(false);
    setOpen(false);
  };

  const handleDelete = async () => {
    const { status, data } = await destroy(`${COMMUNITY_DELETE}/${contactId}`);
    if (status === true) {
      enqueueSnackbar(data, { variant: "success" });
      handleInvalidate();
    }
    handleClose();
    handleCloseDelete();
  };

  const handleSaveCard = async () => {
    const cardPhone = currentCard?.contact_card_phone?.map((item) => ({
      country_phone: item.country_code,
      phone_number: item.phone,
      phone_type: "",
    }));

    const emails = currentCard?.contact_card_email?.map((item) => item.email);
    const websites = currentCard?.contact_card_websites?.map((item) =>
      formatUrl(item.url)
    );

    const facebook = formatUrl(currentCard?.facebook);
    const instagram = formatUrl(currentCard?.instagram);
    const linkedin = formatUrl(currentCard?.linkedin);
    const pinterest = formatUrl(currentCard?.pinterest);
    const threads = formatUrl(currentCard?.threads);
    const tiktok = formatUrl(currentCard?.tiktok);
    const ig = formatUrl(currentCard?.ig);
    const vimeo = formatUrl(currentCard?.vimeo);
    const youtube = formatUrl(currentCard?.youtube);
    const clubhouse = formatUrl(currentCard?.clubhouse);
    const calendar = formatUrl(currentCard?.calendar);

    const social_media = [
      { media_type: "facebook", url: facebook },
      { media_type: "instagram", url: instagram },
      { media_type: "linkedin", url: linkedin },
      { media_type: "pinterest", url: pinterest },
      { media_type: "threads", url: threads },
      { media_type: "tiktok", url: tiktok },
      { media_type: "youtube", url: youtube },
      { media_type: "vimeo", url: vimeo },
      { media_type: "ig", url: ig },
      { media_type: "clubhouse", url: clubhouse },
      { media_type: "calendar", url: calendar },
    ];

    const { status, message, data } = await post(
      `${SAVE_CARD}/${currentCard.uuid}`,
      {
        full_name: currentCard?.fullName,
        profile_image: currentCard?.profileImage,
        address: currentCard?.address,
        backgroundImage: currentCard?.backgroundImage,
        department: currentCard?.department,
        company: currentCard?.company,
        headline: currentCard?.headline,
        logoImage: currentCard?.logoImage,
        lat: currentCard?.lat,
        long: currentCard?.long,
        company_position: currentCard?.title,
        cardPhone: cardPhone || null,
        cardEmails: emails || null,
        cardWebsites: websites || null,
        social_media,
      }
    );

    if (status === true) {
      const link = document.createElement("a");
      link.download = "temp.vcf";
      link.href = data.url;
      link.click();

      enqueueSnackbar("VCF file has been downloaded successfully", {
        variant: "success",
      });
      handleClose();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const getListing = async () => {
    let url;

    url = new URL(COMMUNITY_LISTING);
    url.searchParams.append("page", paginationModel.page + 1);
    url.searchParams.append("limit", paginationModel.pageSize);
    if (isSearch) url.searchParams.append("search", searchParam);

    const { status, message, data } = await get(url.toString());
    if (status) {
      const newData = data?.data?.map((item) => ({
        ...item,
        contactCard: {
          ...item.contactCard,
          cardEmails: item.contactCard.contact_card_email,
          cardPhones: item.contactCard.contact_card_phone.map((phone) => ({
            phone: `${phone.country_code}${phone.phone}`,
            countryCode: phone.country_code,
          })),
          cardWebsites: item.contactCard.contact_card_websites,
        },
      }));
      setCards(newData);
      setTotalCount(data.totalCount);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const listingQuery = useQuery(
    [queryNames.CONTACT, paginationModel.page, searchParam],
    getListing,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getAllListing = async () => {
    setLoading(true);
    const { status, message, data } = await get(ALL_COMMUNITY_LISTING);
    if (status) {
      const newData = data?.data?.map((item) => ({
        ...item,
        contactCard: {
          ...item.contactCard,
          clubhouse: formatUrl(item?.contactCard?.clubhouse),
          calendar: formatUrl(item?.contactCard?.calendar),
          facebook: formatUrl(item?.contactCard?.facebook),
          instagram: formatUrl(item?.contactCard?.instagram),
          linkedin: formatUrl(item?.contactCard?.linkedin),
          pinterest: formatUrl(item?.contactCard?.pinterest),
          threads: formatUrl(item?.contactCard?.threads),
          tiktok: formatUrl(item?.contactCard?.tiktok),
          ig: formatUrl(item?.contactCard?.ig),
          vimeo: formatUrl(item?.contactCard?.vimeo),
          youtube: formatUrl(item?.contactCard?.youtube),
          cardEmails: item?.contactCard?.contact_card_email,
          cardPhones: item?.contactCard?.contact_card_phone?.map((phone) => ({
            phone: formatPhoneNumber(`${phone.country_code}${phone.phone}`),
            countryCode: phone.country_code,
          })),
          cardWebsites: item?.contactCard?.contact_card_websites?.map(
            (websites) => ({
              ...websites,
              url: formatUrl(websites.url),
            })
          ),
        },
      }));

      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("My Community Sheet");
      sheet.properties.defaultRowHeight = 150;

      sheet.mergeCells("A1:R1");

      sheet.getCell("A1").alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      sheet.getRow(1).height = 50;
      sheet.getRow(2).height = 50;
      sheet.getRow(2).values = [
        "Full Name",
        "Emails",
        "Phone Numbers",
        "Title",
        "Department",
        "Company",
        "Head Line",
        "Address",
        "Facebook",
        "Instagram",
        "Linkedin",
        "Pinterest",
        "Threads",
        "Tiktok",
        "YouTube",
        "Clubhouse",
        "Calendar",
        "Websites",
      ];

      sheet.columns = [
        { header: "Full Name", key: "fullName", width: 30 },
        {
          header: "Emails",
          key: "emails",
          width: 30,
        },
        {
          header: "Phone Numbers",
          key: "phoneNumbers",
          width: 30,
        },
        {
          header: "Title",
          key: "title",
          width: 30,
        },
        {
          header: "Department",
          key: "department",
          width: 30,
        },
        { header: "Company", key: "company", width: 30 },
        { header: "Head Line", key: "headline", width: 30 },
        { header: "Address", key: "address", width: 30 },
        {
          header: "Facebook",
          key: "facebook",
          width: 30,
        },
        {
          header: "Instagram",
          key: "instagram",
          width: 30,
        },
        {
          header: "Linkedin",
          key: "linkedin",
          width: 30,
        },
        {
          header: "Pinterest",
          key: "pinterest",
          width: 30,
        },
        {
          header: "Threads",
          key: "threads",
          width: 30,
        },
        {
          header: "Tiktok",
          key: "tiktok",
          width: 30,
        },
        {
          header: "YouTube",
          key: "youtube",
          width: 30,
        },
        {
          header: "Ig",
          key: "ig",
          width: 30,
        },
        {
          header: "Vimeo",
          key: "vimeo",
          width: 30,
        },
        {
          header: "Clubhouse",
          key: "clubhouse",
          width: 30,
        },
        {
          header: "Calendar",
          key: "calendar",
          width: 30,
        },

        {
          header: "Websites",
          key: "websites",
          width: 30,
        },
      ];

      sheet.getCell("A1").value = "My Community";

      sheet.getRow(2).border = {
        top: { style: "thin", color: { argb: "FF808080" } },
        left: { style: "thin", color: { argb: "FF808080" } },
        bottom: { style: "thin", color: { argb: "FF808080" } },
        right: { style: "thin", color: { argb: "FF808080" } },
      };

      sheet.getRow(2).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" },
      };

      sheet.getRow(1).font = {
        name: "Calibri",
        family: 4,
        size: 13,
        bold: true,
      };
      sheet.getRow(2).font = {
        name: "Calibri",
        family: 4,
        size: 13,
        bold: true,
      };
      sheet.eachRow((row) => {
        row.eachCell((cell) => {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
        });
      });

      const promise = Promise.all(
        newData?.map(async (connection, index) => {
          const rowNumber = index + 3;
          sheet.getRow(rowNumber).height = 150;

          const websites =
            connection?.contactCard?.cardWebsites?.length > 0
              ? connection.contactCard.cardWebsites
                  .map((website) => website.url)
                  .join("\n")
              : "-";

          const emails =
            connection?.contactCard?.cardEmails?.length > 0
              ? connection.contactCard.cardEmails
                  .map((email) => email.email)
                  .join("\n")
              : "-";

          const phoneNumbers =
            connection?.contactCard?.cardPhones?.length > 0
              ? connection.contactCard.cardPhones
                  .map((phoneNumber) => `${phoneNumber.phone}`)
                  .join("\n")
              : "-";

          sheet.getRow(rowNumber).values = [
            `${connection?.contactCard?.fullName || "-"}`,
            emails,
            phoneNumbers,
            connection?.contactCard?.title || "-",
            connection?.contactCard?.department || "-",
            connection?.contactCard?.company || "-",
            connection?.contactCard?.headline || "-",
            connection?.contactCard?.address || "-",
            connection?.contactCard?.facebook || "-",
            connection?.contactCard?.instagram || "-",
            connection?.contactCard?.linkedin || "-",
            connection?.contactCard?.pinterest || "-",
            connection?.contactCard?.threads || "-",
            connection?.contactCard?.tiktok || "-",
            connection?.contactCard?.ig || "-",
            connection?.contactCard?.vimeo || "-",
            connection?.contactCard?.youtube || "-",
            connection?.contactCard?.clubhouse || "-",
            connection?.contactCard?.calendar || "-",
            websites,
          ];

          sheet.eachRow((row) => {
            row.eachCell((cell) => {
              cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
              };
            });
          });
        })
      );

      promise.then(() => {
        workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = `Community ${Date.now()}.xlsx`;
          anchor.click();
          window.URL.revokeObjectURL(url);
          setLoading(false);
        });
      });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
    setLoading(false);
  };

  const handleInvalidate = () => {
    client.invalidateQueries(queryNames.CONTACT);
  };

  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setIsHandleChangeSearch(true);
    if (e.target.value.length < 1) {
      setIsHandleChangeSearch(false);
    }
  };

  const handleSearch = () => {
    if (filterText.length > 0) {
      setIsSearch(true);
      setSearchParam(filterText);
    }
  };

  const handleCancleSearch = () => {
    setIsSearch(false);
    setSearchParam("");
    setFilterText("");
  };

  // const filteredCards = useMemo(() => {
  // 	const filtered = cards?.filter((item) =>
  // 		`${item.contactCard.fullName || ""} ${item.contactCard.cardName || ""}`
  // 			.toLowerCase()
  // 			.includes(filterText.toLowerCase())
  // 	);

  // 	return filtered;
  // }, [filterText, cards]);

  if (listingQuery.isLoading || listingQuery.isFetching) {
    return <Loader />;
  }

  return (
    <Box sx={{ position: "relative" }}>
      <LoadingBackDrop loading={loading} />
      <Stack direction="row">
        <Main open={open} mobileView={mobileView}>
          <Box
            component="div"
            sx={{
              borderTop: "1px solid #EBEBEE",
              marginTop: "20px",
              py: "20px",
            }}
          >
            <Stack sx={styles.topWrapper}>
              <PageTitle title="My Community" />
              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                flexWrap={{ md: "nowrap", xs: "wrap" }}
              >
                <FormInput
                  placeholder="Search..."
                  value={filterText}
                  onChange={handleFilter}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isSearch ? (
                          <IconButton onClick={handleCancleSearch}>
                            <Clear />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={handleSearch}
                            sx={
                              isHandleChangeSearch
                                ? styles.filledSearchBtn
                                : styles.searchBtn
                            }
                          >
                            <SearchIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSearch();
                  }}
                />

                <Button
                  variant="contained"
                  sx={styles.exportBtn}
                  onClick={() => (cards?.length > 0 ? getAllListing() : null)}
                >
                  Export
                </Button>
              </Stack>
            </Stack>
            {cards?.length > 0 ? (
              <Box sx={styles.gridWrapper}>
                {
                  cards?.map((card) => (
                    <Box sx={styles.card} key={card.id}>
                      <Box>
                        <Box sx={styles.profileWrapper}>
                          {card?.contactCard?.profileImage ? (
                            <Box
                              component="img"
                              src={card?.contactCard?.profileImage}
                            />
                          ) : (
                            <PlaceholderImage />
                          )}
                        </Box>
                      </Box>

                      <IconButton
                        sx={styles.menuBtn}
                        onClick={(e) => handleClick(e, card)}
                        aria-controls={menuOpen ? "menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={menuOpen ? "true" : undefined}
                      >
                        <MoreVertOutlined />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        id="menu"
                        open={menuOpen}
                        onClose={handleClose}
                        // onClick={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            mt: 1,
                            borderRadius: "5px",
                            width: 200,
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem onClick={() => handleDrawerOpen(card)}>
                          View
                        </MenuItem>
                        {/* <MenuItem onClick={handleEditNavigation}>Edit</MenuItem> */}
                        {/* <MenuItem onClick={handleDuplicate}>
                        Duplicate Card
					</MenuItem> */}
                        {/* <Divider /> */}
                        <MenuItem onClick={handleOpenDelete}>Delete</MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setOpenQrModal(true);
                          }}
                        >
                          Download QR
                        </MenuItem>
                        <MenuItem onClick={handleSaveCard}>Save Card</MenuItem>
                      </Menu>
                      <Typography component="h3">
                        {card?.contactCard?.cardName
                          ? card?.contactCard?.cardName
                          : card?.contactCard?.fullName}
                      </Typography>
                      <Typography component="p">
                        {card?.contactCard?.cardEmails?.length > 0
                          ? card?.contactCard?.cardEmails[0].email
                          : ""}
                      </Typography>
                    </Box>
                  ))
                  //   )
                }
                {/* <AddBusinessCard /> */}
              </Box>
            ) : (
              <Box sx={styles.noData}>
                <Typography component="h1">No record found</Typography>
              </Box>
            )}
          </Box>

          <Pagination
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            totalCount={totalCount}
          />
        </Main>

        <RightBusinessDrawer
          open={open}
          inViewMode={true}
          handleDrawerClose={handleDrawerClose}
          handleOpenDelete={handleOpenDelete}
          isComunity={true}
          {...currentCard}
        />
      </Stack>

      <ConfirmationModal
        header="Are you sure?"
        title={`Do you really want to delete this record. This process cannot be undone.`}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        open={openDeleteModal}
        handleClose={handleCloseDelete}
        handleConfirm={handleDelete}
      />

      <QrCodeModel
        open={openQrModal}
        handleClose={() => setOpenQrModal(false)}
        uuid={currentCard.uuid}
        personName={currentCard.fullName}
        qrCodeUrl={currentCard.qrCodeUrl}
        shareURL={currentCard?.shareURL}
      />
    </Box>
  );
};

export default ConnectionsListing;
