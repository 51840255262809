import React from "react";
import { ReactComponent as LinkedIn } from "../../../assets/svg/businesscard/LinkedIn.svg";
import { ReactComponent as Facebook } from "../../../assets/svg/businesscard/Facebook.svg";
import { ReactComponent as Instagram } from "../../../assets/svg/businesscard/Instagram.svg";
import { ReactComponent as Stripe } from "../../../assets/svg/businesscard/Stripe1.svg";
import { ReactComponent as Paypal } from "../../../assets/svg/businesscard/Paypal2.svg";
import { ReactComponent as Cashapp } from "../../../assets/svg/businesscard/Cashapp2.svg";
import { ReactComponent as Applepay } from "../../../assets/svg/businesscard/Applepay2.svg";
import { ReactComponent as Venmo } from "../../../assets/svg/businesscard/Venmo2.svg";
import { ReactComponent as Globe } from "../../../assets/svg/businesscard/Globe.svg";
import { ReactComponent as Email } from "../../../assets/svg/businesscard/Email.svg";
import { ReactComponent as Phone } from "../../../assets/svg/businesscard/Phone.svg";
import { ReactComponent as Location } from "../../../assets/svg/businesscard/Location.svg";
import { ReactComponent as Company } from "../../../assets/svg/businesscard/Company.svg";
import { ReactComponent as Tiktok } from "../../../assets/svg/createCard/Tiktok.svg";
import { ReactComponent as PInterest } from "../../../assets/svg/createCard/PInterest.svg";
import { ReactComponent as Threads } from "../../../assets/svg/createCard/Threads.svg";
import { ReactComponent as Clubhouse } from "../../../assets/svg/createCard/Clubhouse.svg";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Stack, Typography, Skeleton } from "@mui/material";
import { formatUrl, formatPhoneNumber } from "../../../utils/helpers/formatter";
import { maxWidth } from "@mui/system";
import PlaceholderImage from "../../../assets/images/PlaceholderImage.jpg";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Logo from "../../../assets/images/shareLogo.png";
import VideoTemplate from "./VideoTemplate";

const styles = {
  card: {
    border: "1px solid #1C5FAE",
    borderRadius: 4,
    "& h5": {
      color: "#1E1E1F",
      fontSize: 14,
      wordBreak: "break-all",
      "& a": {
        color: "inherit",
        textDecoration: "none",
      },
    },
  },
  detail: {
    p: "45px 16px 16px",
    "& .qr-code": {
      width: 45,
      height: 45,
    },
    "& svg": {
      minWidth: 15,
    },
  },

  coverImgWrapper: {
    height: 180,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "16px 16px 0px 0px",
    },
  },
  coverSkeleton: {
    background: "#eee",
    height: 180,
    borderTopRightRadius: 18,
    borderTopLeftRadius: 18,
  },
  coverWrapper: {
    position: "relative",
  },
  nameWrapper: {
    color: "#000",
    textAlign: "center",
    "& h2": {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.1,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
      marginInline: "auto",
    },
    "& h6": {
      fontSize: 13,
      pt: 0.5,
      marginInline: "auto",
    },
  },
  profileWrapper: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    top: 100,
    width: 100,
    height: 100,
    borderRadius: "50%",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "2px solid #fff",
      background: "#F9FAFC",
    },
  },
  logo: {
    width: "100%",
    height: 40,
    textAlign: "center",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    top: 20,
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  logoSkeleton: {
    maxWidth: 200,
  },
  desc: {
    color: "#1E1E1F",
    fontSize: 17,
    fontWeight: 600,
    lineHeight: 1.2,
    p: "15px 0 10px ",
    wordBreak: "break-all",
  },
  socialIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 30,
    height: 30,
    borderRadius: "50%",
    "& svg": {
      width: 17,
      height: 17,
    },
  },
};

function TemplateTwo({
  inViewMode,
  fullName,
  title,
  department,
  profileImage,
  backgroundImage,
  logoImage,
  headline,
  cardEmails,
  cardPhones,
  address,
  company,
  primaryColor,
  QRCode,
  instagram,
  website,
  cardWebsites,
  facebook,
  venmo,
  applepay,
  cashapp,
  stripe,
  paypal,
  linkedin,
  tiktok,
  pinterest,
  clubhouse,
  threads,
  calendar,
  youtube,
  vimeo,
  ig,
}) {
  return (
    <>
      <Box sx={styles.card}>
        <Box sx={styles.coverWrapper}>
          {backgroundImage ? (
            <Box sx={styles.coverImgWrapper}>
              <Box
                component="img"
                src={
                  backgroundImage
                    ? typeof backgroundImage === "object"
                      ? URL.createObjectURL(backgroundImage)
                      : backgroundImage.includes("amazonaws")
                      ? backgroundImage
                      : backgroundImage
                    : null
                }
              />
            </Box>
          ) : (
            <Box sx={styles.coverSkeleton}></Box>
          )}
          {logoImage ? (
            <Box sx={styles.logo}>
              <Box
                component="img"
                src={
                  logoImage
                    ? typeof logoImage === "object"
                      ? URL.createObjectURL(logoImage)
                      : logoImage
                    : null
                }
              ></Box>
            </Box>
          ) : inViewMode && !logoImage ? null : (
            // : !logoImage ? (
            //   <Box
            //     component="img"
            //     src={Logo}
            //     sx={{ ...styles.logo, ...styles.logoSkeleton, width: "unset" }}
            //   />
            // )

            <Skeleton
              sx={{ ...styles.logo, ...styles.logoSkeleton }}
              variant="rounded"
              maxWidth={180}
            />
          )}
          <Box sx={styles.profileWrapper}>
            {profileImage ? (
              <Box
                component="img"
                sx={styles.profileImage}
                src={
                  profileImage
                    ? typeof profileImage === "object"
                      ? URL.createObjectURL(profileImage)
                      : profileImage
                    : null
                }
              />
            ) : inViewMode && !profileImage ? (
              <Box
                component="img"
                src={PlaceholderImage}
                sx={styles.dummyImage}
              />
            ) : (
              <Skeleton variant="circular" width={100} height={100} />
            )}
          </Box>
        </Box>
        <Box sx={styles.detail}>
          <Box sx={styles.nameWrapper} pb={0.5}>
            {company ? (
              <Typography component="h2" sx={{ color: "#000" }}>
                {company}
              </Typography>
            ) : inViewMode ? null : (
              <Skeleton
                variant="rounded"
                sx={{ mx: "auto" }}
                width={150}
                height={15}
              />
            )}
          </Box>
          <Box sx={styles.nameWrapper} pb={2}>
            {fullName ? (
              <Typography
                component="h2"
                sx={{ color: primaryColor ? primaryColor : "#000" }}
              >
                {fullName}
              </Typography>
            ) : inViewMode ? null : (
              <Skeleton
                variant="rounded"
                sx={{ mx: "auto" }}
                width={150}
                height={15}
              />
            )}
            <Typography component="h6">
              {title ? (
                <>
                  {title} {department ? `/ ${department}` : null}
                </>
              ) : department ? (
                <>{department}</>
              ) : inViewMode ? null : (
                <Skeleton
                  variant="rounded"
                  sx={{ mx: "auto" }}
                  width={100}
                  height={15}
                />
              )}
            </Typography>
          </Box>
          <Stack
            direction="row"
            justifyContent="center"
            gap={1}
            flexWrap="wrap"
          >
            {facebook ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(facebook)}
                target="_blank"
              >
                <Facebook />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}
{/* 
            {cashapp ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(cashapp)}
                target="_blank"
              >
                <Cashapp />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}

            {applepay ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(applepay)}
                target="_blank"
              >
                <Applepay />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}

            {venmo ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(venmo)}
                target="_blank"
              >
                <Venmo />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}

            {stripe ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(stripe)}
                target="_blank"
              >
                <Stripe />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}

            {paypal ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(paypal)}
                target="_blank"
              >
                <Paypal />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )} */}
            {/* {youtube ? (
							<Box
								sx={{
									...styles.socialIcon,
									bgcolor: primaryColor ? primaryColor : "#eee",
									"& path": {
										fill: primaryColor ? "#fff" : "#000",
									},
								}}
								component="a"
								href={formatUrl(youtube)}
								target="_blank"
							>
								<YouTubeIcon />
							</Box>
						) : inViewMode ? null : (
							<Skeleton variant="circular" width={20} height={20} />
						)} */}
            {linkedin ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(linkedin)}
                target="_blank"
              >
                <LinkedIn />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}
            {instagram ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(instagram)}
                target="_blank"
              >
                <Instagram />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}
            {/* {tiktok ? (
							<Box
								sx={{
									...styles.socialIcon,
									bgcolor: primaryColor ? primaryColor : "#eee",
									"& path": {
										fill: primaryColor ? "#fff" : "#000",
									},
								}}
								component="a"
								href={formatUrl(tiktok)}
								target="_blank"
							>
								<Tiktok />
							</Box>
						) : inViewMode ? null : (
							<Skeleton variant="circular" width={20} height={20} />
						)} */}
            {pinterest ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(pinterest)}
                target="_blank"
              >
                <PInterest />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}
            {threads ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(threads)}
                target="_blank"
              >
                <Threads />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}
            {clubhouse ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(clubhouse)}
                target="_blank"
              >
                <Clubhouse />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}
            {calendar ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(calendar)}
                target="_blank"
              >
                <CalendarMonthIcon />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}
          </Stack>
          {headline ? (
            <Typography sx={styles.desc}>{headline}</Typography>
          ) : inViewMode ? null : (
            <Skeleton
              variant="rounded"
              width={200}
              height={15}
              sx={{ mt: 2 }}
            />
          )}
          {cardEmails?.length ? (
            cardEmails.map((single) => (
              <AdditionalInformation
                icon={<Email />}
                info={single.email}
                primaryColor={primaryColor}
                href={`mailto:${single.email}`}
                key={single.id}
              />
            ))
          ) : inViewMode ? null : (
            <Stack direction="row" columnGap={2} my={1} alignItems="center">
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
              ></Box>
              <Skeleton
                variant="rounded"
                width={150}
                height={15}
                sx={{ mt: 1 }}
              />
            </Stack>
          )}
          {cardPhones?.length ? (
            cardPhones.map((single) => (
              <AdditionalInformation
                icon={<Phone />}
                info={formatPhoneNumber(single.phone)}
                href={`tel:${single.phone}`}
                primaryColor={primaryColor}
                key={single.id}
              />
            ))
          ) : inViewMode ? null : (
            <Stack direction="row" columnGap={2} my={1} alignItems="center">
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
              ></Box>
              <Skeleton
                variant="rounded"
                width={150}
                height={15}
                sx={{ mt: 1 }}
              />
            </Stack>
          )}
          <AdditionalInformation
            icon={<Location />}
            info={inViewMode ? address : address?.address}
            infoType="address"
            primaryColor={primaryColor}
            inViewMode={inViewMode}
          />

          {cardWebsites?.length > 0 &&
            cardWebsites.map((website, index) => {
              return (
                <AdditionalInformation
                  icon={<Globe />}
                  info={website.title}
                  href={formatUrl(website.url)}
                  primaryColor={primaryColor}
                  inViewMode={inViewMode}
                  key={index}
                />
              );
            })}
  {cashapp && (
            <>
               <AdditionalInformation
                  icon={<Cashapp />}
                  info={cashapp}
                  href={formatUrl(cashapp)}
                  primaryColor={primaryColor}
                  inViewMode={inViewMode}
                />
            </>
          )}

          {applepay && (
            <>
              <AdditionalInformation
                  icon={<Applepay />}
                  info={applepay}
                  href={formatUrl(applepay)}
                  primaryColor={primaryColor}
                  inViewMode={inViewMode}
                />
            </>
          )}

      
          {venmo && (
            <>
              <AdditionalInformation
                  icon={<Venmo />}
                  info={venmo}
                  href={formatUrl(venmo)}
                  primaryColor={primaryColor}
                  inViewMode={inViewMode}
                />
            </>
          )}

          {paypal && (
            <>
             <AdditionalInformation
                  icon={<Paypal />}
                  info={paypal}
                  href={formatUrl(paypal)}
                  primaryColor={primaryColor}
                  inViewMode={inViewMode}
                />
            </>
          )}

          {stripe && (
            <>
              <AdditionalInformation
                  icon={<Stripe />}
                  info={stripe}
                  href={formatUrl(stripe)}
                  primaryColor={primaryColor}
                  inViewMode={inViewMode}
                />
            </>
          )}
          {QRCode ? (
            <Stack pb={1} flexDirection="row" justifyContent="flex-end">
              <QRCode className="qr-code" />
            </Stack>
          ) : null}

          <VideoTemplate
            styles={styles}
            youtube={youtube}
            tiktok={tiktok}
            vimeo={vimeo}
            ig={ig}
          />
        </Box>
      </Box>
    </>
  );
}

const AdditionalInformation = ({
  inViewMode,
  icon,
  info = null,
  primaryColor = "",
  href,
  infoType,
}) => {
  return (
    <>
      <Stack direction="row" columnGap={2} my={1} alignItems="center">
        {inViewMode && !info ? null : (
          <Box
            sx={{
              ...styles.socialIcon,
              bgcolor: primaryColor ? primaryColor : "#eee",
              "& path": {
                fill: primaryColor ? "#fff" : "#000",
              },
            }}
          >
            {info ? icon : null}
          </Box>
        )}

        {info ? (
          <Typography component="h5">
            {infoType ? (
              <Typography
                component="a"
                href={`http://maps.google.com/?q=${info}`}
                target="_blank"
              >
                {info}
              </Typography>
            ) : (
              <Typography component="a" href={href} target="_blank">
                {info}
              </Typography>
            )}
          </Typography>
        ) : inViewMode ? null : (
          <Skeleton variant="rounded" width={150} height={15} sx={{ mt: 1 }} />
        )}
      </Stack>
    </>
  );
};

export default TemplateTwo;
