import { useState } from "react";

import {
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import PageTitle from "../../../components/common/PageTitle";

import {
  TEAM_LISTING,
  CUSTOMER_CHANGE_ORDER_STATUS,
  CUSTOMER_EXPORT_ORDERS,
} from "../../../data/constants/apiRoutes";
import { get, post } from "../../../server";
import queryNames from "../../../data/constants/queryNames";
import DataGridListing from "../../../components/listing/DataGridListing";
import { Close, Edit, RemoveRedEyeOutlined } from "@mui/icons-material";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import SelectInput from "../../../components/forms/SelectInput";
import LoadingButton from "../../../components/button/LoadingButton";
import { useNavigate } from "react-router-dom";
import routes from "../../../data/constants/routes";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelJS from "exceljs";
import LoadingBackDrop from "../../../components/overlay/LoadingBackDrop";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: 360, xs: 310 },
    // height: 280,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 15,
    p: 2,
    "& button": {
      width: 120,
      boxShadow: "none",
      fontWeight: 600,
    },
  },
  iconBox: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    textAlign: "center",
    border: "3px solid #E53935",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  crossIcon: {
    width: 40,
    height: 40,
    color: "#E53935",
  },
  closeIconBtnWrapper: {
    textAlign: "end",
  },
  closeIconBtn: {
    textAlign: "end",
    width: "40px !important",
    height: 40,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 600,
    flexGrow: 1,
    textAlign: "center",
    color: "#636363",
  },
  confirmTitle: {
    color: "#838383",
    marginInline: "10px",
    fontSize: 14,
  },
  cancelBtn: {
    bgcolor: "#E53935",
    color: "#ffffff",
    height: 45,
    textTransform: "capitalize",
    "&:hover": { bgcolor: "#E53935" },
  },
  confirmBtn: {
    bgcolor: "#D3A23B",
    color: "#ffffff",
    height: 45,
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      bgcolor: "#D3A23B",
    },
  },

  btnsWrapper: {
    mt: 4,
    mb: 2,
  },
  title: {
    textTransform: "capitalize",
  },
};

const changeStatusValues = [
  { id: "pending", name: "Pending" },
  { id: "approved", name: "Approved" },
  { id: "completed", name: "Completed" },
];

const TeamsListing = () => {
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [errors, setErrors] = useState({});

  const [isSearch, setIsSearch] = useState(false);
  const [isHandleChangeSearch, setIsHandleChangeSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchedValue, setSearchedValue] = useState("");

  const navigate = useNavigate();

  const client = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setIsHandleChangeSearch(true);
    if (e.target.value.length < 1) {
      setIsHandleChangeSearch(false);
      setIsSearch(false);
      setSearchedValue("");
    }
  };

  const handleSearchClick = () => {
    setIsSearch(true);
    setSearchedValue(search);
  };

  const handleClearSearch = () => {
    setIsSearch(false);
    setSearch("");
    setSearchedValue("");
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSelectedData({ ...selectedData, [name]: value });
  };

  const handleClose = () => {
    setSelectedData({});
    setErrors({});
    setOpen(false);
  };

  const fetchOrders = async (pageNo, limit, searchParam, isSearchParam) => {
    let url;

    url = new URL(TEAM_LISTING);
    url.searchParams.append("page", pageNo + 1);
    url.searchParams.append("limit", limit);
    if (isSearch) {
      url.searchParams.append("search", search);
    }

    return await get(url.toString());
  };

  const handleFormRequest = async () => {
    const { status, message } = await post(
      `${CUSTOMER_CHANGE_ORDER_STATUS}/${selectedData.id}`,
      selectedData
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      handleClose();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleFormRequest, {
    onSuccess: () => {
      client.invalidateQueries([queryNames.ORDER]);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate();
  };

  const columns = [
    {
      field: "logo",
      headerName: "Team Logo",
      sortable: false,

      renderCell: (params) => {
        return (
          <>
            <Avatar
              src={params.row.logo}
              sx={{ mr: 1.5, width: 40, height: 40 }}
            >
              <Typography sx={styles.title}>
                {params?.row?.name?.charAt(0)}
              </Typography>
            </Avatar>
          </>
        );
      },
    },

    {
      field: "name",
      headerName: "Team Name",
      flex: 1,
      renderCell: (params) => {
        return <Typography sx={styles.title}>{params?.row?.name}</Typography>;
      },
    },
    {
      field: "teamOwneName",
      headerName: "Team Owner",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={styles.title}>
            {params?.row?.ownerData &&
              `${params?.row?.ownerData?.first_name} ${params?.row?.ownerData?.last_name}`}
          </Typography>
        );
      },
    },
    {
      field: "teamOwneEmail",
      headerName: "Team Owner Email",
      flex: 1,
      valueGetter: (params) =>
        params?.row?.ownerData && params?.row?.ownerData?.email,
    },

    {
      field: "members",
      headerName: "Members",

      valueGetter: (params) =>
        params?.row?.members && params?.row?.members?.length + 1,
    },

    {
      field: "action",
      headerName: "Action",
      width: 130,
      sortable: false,

      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" alignItems="center" gap={1}>
              <Tooltip title="Edit Team" placement="top">
                <IconButton
                  disableRipple
                  onClick={() =>
                    navigate(`${routes.ADMIN_EDIT_TEAM}/${params?.row?.id}`)
                  }
                  size="small"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="View Detail" placement="top">
                <IconButton
                  disableRipple
                  size="small"
                  onClick={() =>
                    navigate(`${routes.ADMIN_TEAM_DETAIL}/${params?.row?.id}`)
                  }
                >
                  <RemoveRedEyeOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          </>
        );
      },
    },
  ];
  return (
    <>
      <PageTitle
        title="Teams"
        searchInput
        placeholder="Search..."
        search={search}
        handleSearchChange={handleSearchChange}
        isSearch={isSearch}
        handleClearSearch={handleClearSearch}
        handleSearchClick={handleSearchClick}
        isHandleChangeSearch={isHandleChangeSearch}
      />
      <DataGridListing
        columns={columns}
        fetchRecords={fetchOrders}
        queryName={queryNames.ORDER}
        search={searchedValue}
        isSearch={isSearch}
      />

      <Modal
        open={open}
        aria-describedby="modal-modal-description"
        size="small"
        onClose={handleClose}
      >
        <Box sx={styles.container}>
          <Box sx={styles.closeIconBtnWrapper}>
            <IconButton onClick={handleClose} sx={styles.closeIconBtn}>
              <Close />
            </IconButton>
          </Box>

          <SelectInput
            withasterisk={true}
            label="Change Status"
            value={selectedData.status || ""}
            onChange={handleChange}
            name="status"
            error={errors?.status ? true : false}
            errorMessage={errors?.status || ""}
            renderValue={(selected) => {
              if (selected?.length === 0) {
                return <span>Select Status</span>;
              }

              const found = changeStatusValues.find(
                (single) => single?.id === selected
              );

              return found?.name;
            }}
            displayEmpty
          >
            {changeStatusValues.map((statusValue, index) => {
              return (
                <MenuItem value={statusValue.id} key={index}>
                  {statusValue.name}
                </MenuItem>
              );
            })}
          </SelectInput>

          <Stack
            direction="row"
            sx={styles.btnsWrapper}
            justifyContent="center"
            spacing={2}
            textAlign="justify"
          >
            <Button
              variant="contained"
              sx={styles.cancelBtn}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              sx={styles.confirmBtn}
              onClick={handleSubmit}
              isLoading={mutation.isLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default TeamsListing;
