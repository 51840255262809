import { ReactComponent as Members } from "../../assets/svg/sidebarIcons/Members.svg";
import { ReactComponent as BusinessCard } from "../../assets/svg/sidebarIcons/BusinessCard.svg";
import { ReactComponent as Orders } from "../../assets/svg/sidebarIcons/Orders.svg";
import { ReactComponent as Contact } from "../../assets/svg/sidebarIcons/Contact.svg";
import { ReactComponent as Product } from "../../assets/svg/sidebarIcons/Product.svg";
import { ReactComponent as Reports } from "../../assets/svg/sidebarIcons/Reports.svg";
import { ReactComponent as Settings } from "../../assets/svg/sidebarIcons/Settings.svg";
import { ReactComponent as Terms } from "../../assets/svg/sidebarIcons/Terms.svg";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoIcon from "@mui/icons-material/Info";
import PolicyIcon from "@mui/icons-material/Policy";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import { ReactComponent as Team } from "../../assets/svg/Team.svg";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import routes from "./routes";

const adminSiderbarRoutes = [
  {
    label: "Customers",
    icon: <Members />,
    path: routes.ADMIN_CUSTOMERS,
  },
  {
    label: "Teams",
    icon: <Team />,
    path: routes.ADMIN_TEAMS,
  },
  {
    label: "KnocKnoc Card",
    icon: <BusinessCard />,
    path: routes.ADMIN_BUSINESS_CARD,
  },
  {
    label: "Print Card Orders",
    icon: <Orders />,
    path: routes.ADMIN_CARD_ORDERS,
  },
  // {
  //   label: "Categories",
  //   icon: <CategoryOutlinedIcon />,
  //   path: routes.ADMIN_CATEGORY,
  // },
  {
    label: "Community eCommerce",
    icon: <Product />,
    path: routes.ADMIN_PRODUCT,
  },
  {
    label: "Send Notification",
    icon: <NotificationAddOutlinedIcon />,
    path: routes.ADMIN_SEND_NOTIFICATION,
  },
  {
    label: "Settings",
    icon: <Settings />,
    path: routes.ADMIN_SETTINGS,
  },
  {
    label: "Print Card Pricing",
    icon: <AttachMoneyIcon />,
    path: routes.ADMIN_PRICING,
  },
  {
    label: "Help",
    icon: <HelpOutlineIcon />,

    subCategories: [
      {
        label: "About Us",
        icon: <InfoIcon />,
        path: routes.ADMIN_ABOUT_US,
      },

      {
        label: "Privacy Policy",
        icon: <PolicyIcon />,
        path: routes.ADMIN_PRIVACY_POLICY,
      },
      {
        label: "Contact Us",
        icon: <Contact />,
        path: routes.ADMIN_CONTACT_US,
      },
      {
        label: "Terms & Conditions",
        icon: <Terms />,
        path: routes.ADMIN_TERMS_AND_CONDITIONS,
      },
    ],
  },
];

export { adminSiderbarRoutes };
