import Joi from "joi";

export const FirstStepSchema = Joi.object({
  fullName: Joi.string().max(50).required().messages({
    "string.empty": `Please enter your full name`,
    "string.max": `Full name must be maximum 50 characters!`,
  }),
  headline: Joi.string().max(100).allow("").allow(null).messages({
    "string.max": `Headline must be maximum 100 characters!`,
  }),
  title: Joi.string().max(100).allow("").allow(null).messages({
    "string.max": `Title must be maximum 100 characters!`,
  }),
  department: Joi.string().max(50).allow("").allow(null).messages({
    "string.max": `Department must be maximum 50 characters!`,
  }),
  company: Joi.string().max(100).allow("").allow(null).messages({
    "string.max": `Company must be maximum 100 characters!`,
  }),
});

export const ThirdStepSchema = Joi.object({
  cardEmails: Joi.array()
    .items(
      Joi.object({
        email: Joi.string()
          .max(70)
          .email({ tlds: { allow: false } })
          .required()
          .messages({
            "string.empty": `Please enter valid email address`,
            "string.max": `Email must be maximum 70 characters!`,
          }),
      })
    )
    .messages({
      "array.includesRequiredUnknowns": `Please enter valid email address`,
    }),

  cardPhones: Joi.array()
    .items(
      Joi.object({
        phone: Joi.string()
          .min(8)
          .max(15)
          .regex(/^([\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/)
          .required()
          .messages({
            "string.empty": `Please enter phone number`,
            "string.min": `Please enter phone number`,
            "string.max": `Phone number can be max 15 characters long`,
            "string.pattern.base": `Phone number should match the format of something like 92(567)-456788 or just numbers`,
          }),
      })
    )
    .messages({
      "array.includesRequiredUnknowns": `Please enter vaild phone number`,
    }),

  cardWebsites: Joi.array()
    .items(
      Joi.object({
        url: Joi.string()
          .pattern(
            new RegExp(
              "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
              "i"
            )
          )
          .required()
          .messages({
            "string.empty": `Please enter a url`,
            "string.pattern.base": `Please enter a valid url`,
          }),

        title: Joi.string().max(30).required().messages({
          "string.empty": `Please enter website title`,
          "string.max": `Website title must be maximum 30 characters!`,
        }),
      })
    )
    .messages({
      "array.includesRequiredUnknowns": `Please enter valid website`,
    }),

  instagram: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),
  facebook: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),

    cashapp: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),

    
    paypal: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),

    
    stripe: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),

    
    applepay: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),

    
    venmo: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),
  website: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),
  linkedin: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),
  tiktok: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),
  pinterest: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),
  threads: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),
  clubhouse: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),
});

export const FourthStepSchema = Joi.object({
  cardName: Joi.string()
    .trim()
    .required()
    .max(50)
    .messages({
      "string.empty": "Card name is required!", // Ensures it's not empty
      "any.required": "Card name is required!", // Ensures it's explicitly required
      "string.max": "Card name must be maximum 50 characters!",
    }),
});
