const queryNames = {
  PROFILE: "profile",
  PAGE: "page",
  CONTACT_US: "contactUs",
  NOTIFICATION: "notification",
  CUSTOMER: "customer",
  COLORS: "colors",
  PRODUCTS: "products",
  CATEGORIES: "categories",
  CARD: "card",
  CONTACT_CARD: "contactCard",
  CONTACT: "contact",
  TEAM: "team",
  TEAM_MEMBERS: "teamMembers",
  NOTIFICATIONS: "notifications",
  PRICING: "pricing",
  ORDER: "order",
  SUBSCRIPTION: "subscription",
  CUSTOMER_PRODUCTS: "customerProducts",
  CUSTOMER_PUPLOAR_PRODUCTS: "popularProducts",
  CUSTOMER_COMMUNITY_PRODUCTS: "communityProducts",
};

export default queryNames;
