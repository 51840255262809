import { useState } from "react";

import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import PageTitle from "../../../components/common/PageTitle";

import {
  CUSTOMER_ALL_ORDERS,
  CUSTOMER_CHANGE_ORDER_STATUS,
  CUSTOMER_EXPORT_ORDERS,
} from "../../../data/constants/apiRoutes";
import { get, post } from "../../../server";
import queryNames from "../../../data/constants/queryNames";
import DataGridListing from "../../../components/listing/DataGridListing";
import { Close, Edit, RemoveRedEyeOutlined } from "@mui/icons-material";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import SelectInput from "../../../components/forms/SelectInput";
import LoadingButton from "../../../components/button/LoadingButton";
import { useNavigate } from "react-router-dom";
import routes from "../../../data/constants/routes";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelJS from "exceljs";
import LoadingBackDrop from "../../../components/overlay/LoadingBackDrop";
import moment from "moment";
import { toDataURL } from "../../../utils/helpers/formatter";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: 360, xs: 310 },
    // height: 280,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 15,
    p: 2,
    "& button": {
      width: 120,
      boxShadow: "none",
      fontWeight: 600,
    },
  },
  iconBox: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    textAlign: "center",
    border: "3px solid #E53935",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  crossIcon: {
    width: 40,
    height: 40,
    color: "#E53935",
  },
  closeIconBtnWrapper: {
    textAlign: "end",
  },
  closeIconBtn: {
    textAlign: "end",
    width: "40px !important",
    height: 40,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 600,
    flexGrow: 1,
    textAlign: "center",
    color: "#636363",
  },
  confirmTitle: {
    color: "#838383",
    marginInline: "10px",
    fontSize: 14,
  },
  cancelBtn: {
    bgcolor: "#E53935",
    color: "#ffffff",
    height: 45,
    textTransform: "capitalize",
    "&:hover": { bgcolor: "#E53935" },
  },
  confirmBtn: {
    bgcolor: "#D3A23B",
    color: "#ffffff",
    height: 45,
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      bgcolor: "#D3A23B",
    },
  },

  btnsWrapper: {
    mt: 4,
    mb: 2,
  },
  title: {
    textTransform: "capitalize",
  },
};

const changeStatusValues = [
  { id: "pending", name: "Pending" },
  { id: "approved", name: "Approved" },
  { id: "completed", name: "Completed" },
];

const CardOdersListing = () => {
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [isHandleChangeSearch, setIsHandleChangeSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchedValue, setSearchedValue] = useState("");

  const navigate = useNavigate();

  const client = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setIsHandleChangeSearch(true);
    if (e.target.value.length < 1) {
      setIsHandleChangeSearch(false);
      setIsSearch(false);
      setSearchedValue("");
    }
  };

  const handleSearchClick = () => {
    setIsSearch(true);
    setSearchedValue(search);
  };

  const handleClearSearch = () => {
    setIsSearch(false);
    setSearch("");
    setSearchedValue("");
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSelectedData({ ...selectedData, [name]: value });
  };

  const handleOpen = (data) => {
    setSelectedData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedData({});
    setErrors({});
    setOpen(false);
  };

  const fetchOrders = async (pageNo, limit, searchParam, isSearchParam) => {
    let url;

    url = new URL(CUSTOMER_ALL_ORDERS);
    url.searchParams.append("page", pageNo + 1);
    url.searchParams.append("limit", limit);

    if (isSearch) {
      url.searchParams.append("search", search);
    }

    return await get(url.toString());
  };

  const handleExportExcelFile = (orderData) => {
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 190;

    sheet.getRow(1).height = 50;
    sheet.getRow(1).values = [
      "Team Name",
      "Member Name",
      "Quantity",
      "Binding Url",
      "Shipping Address",
      "Card Type",
      "Color Code",
      "Card Logo",
      "QR Code",
    ];

    sheet.columns = [
      { header: "Team Name", key: "teamName", width: 30 },
      { header: "Member Name", key: "memberName", width: 30 },
      {
        header: "Quantity",
        key: "quantity",
        width: 15,
      },
      {
        header: "Binding Url",
        key: "bindingUrl",
        width: 110,
      },
      {
        header: "Shipping Address",
        key: "shippingAddress",
        width: 60,
      },
      {
        header: "Card Type",
        key: "cardType",
        width: 20,
      },
      {
        header: "Color Code",
        key: "color",
        width: 20,
      },
      {
        header: "Card Logo",
        key: "logo",
        width: 60,
      },
      {
        header: "QR Code",
        key: "qrCodeUrl",
        width: 60,
      },
    ];

    sheet.getRow(1).border = {
      top: { style: "thin", color: { argb: "FF808080" } },
      left: { style: "thin", color: { argb: "FF808080" } },
      bottom: { style: "thin", color: { argb: "FF808080" } },
      right: { style: "thin", color: { argb: "FF808080" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFFFF" },
    };

    sheet.getRow(1).font = {
      name: "Calibri",
      family: 4,
      size: 13,
      bold: true,
    };
    sheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });

    const promise = Promise.all(
      orderData?.orderDetail?.map(async (order, index) => {
        const rowNumber = index + 2;
        sheet.getRow(rowNumber).height = 200;

        sheet.getRow(rowNumber).values = [
          `${orderData?.team?.name || "-"}`,
          `${order?.card?.userData?.first_name} ${order?.card?.userData.last_name}`,
          order?.quantity,
          `${import.meta.env.VITE_SHARE_URL}/card/${
            order?.card?.uuid
          }?personName=${order?.card?.userData?.first_name} ${
            order?.card?.userData.last_name
          }`,
          `${orderData?.shippingAddress}`,
          order?.pricing?.name === "basic" ? "Basic" : "Premium",
          order?.color,
          order?.logo,
          order?.card?.qrCodeUrl || "-",
        ];

        // const result = await toDataURL(order?.frontImage);
        // const backImageResult = await toDataURL(order?.backImage);
        // const splitted = order?.frontImage?.split(".");
        // const backImageSplitted = order?.frontImage?.split(".");
        // const extName = splitted[splitted.length - 1];
        // const backImageExtName =
        //   backImageSplitted[backImageSplitted.length - 1];

        // const imageId2 = workbook.addImage({
        //   base64: result.base64Url,
        //   extension: extName,
        // });
        // const imageId3 = workbook.addImage({
        //   base64: backImageResult.base64Url,
        //   extension: backImageExtName,
        // });

        // sheet.addImage(imageId2, {
        //   tl: { col: 6.1, row: rowNumber - 0.99 },

        //   ext: { width: 225, height: 355 },
        // });
        // sheet.addImage(imageId3, {
        //   tl: { col: 7.1, row: rowNumber - 0.99 },

        //   ext: { width: 225, height: 355 },
        // });

        sheet.eachRow((row) => {
          row.eachCell((cell) => {
            cell.alignment = {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            };
          });
        });
      })
    );

    promise.then(() => {
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${
          orderData?.team?.name ||
          `${orderData?.user?.first_name} ${orderData?.user?.last_name}`
        } ${Date.now()}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
        setLoading(false);
      });
    });
  };

  const handleFormRequest = async () => {
    const { status, message } = await post(
      `${CUSTOMER_CHANGE_ORDER_STATUS}/${selectedData.id}`,
      selectedData
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      handleClose();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleFormRequest, {
    onSuccess: () => {
      client.invalidateQueries([queryNames.ORDER]);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate();
  };

  const columns = [
    {
      field: "orderId",
      headerName: "Order Id",
      flex: 1,
      valueGetter: (params) =>
        params?.row?.orderId ? `${params?.row?.orderId}` : "",
    },
    {
      field: "teamName",
      headerName: "Team Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={styles.title}>
            {params?.row?.team?.name ? params?.row?.team?.name : "-"}
          </Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) =>
        params?.row?.user
          ? `${params?.row?.user?.first_name} ${params?.row?.user?.last_name}`
          : "",
    },
    {
      field: "shippingAddress",
      headerName: "Shipping Address",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      valueGetter: (params) =>
        moment(params.row.created_at).format("DD/MMM/YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        return <Typography sx={styles.title}>{params?.row?.status}</Typography>;
      },
    },

    {
      field: "action",
      headerName: "Action",
      // flex: 1,
      width: 160,
      sortable: false,

      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" alignItems="center" gap={1}>
              <Tooltip title="View Detail" placement="top">
                <IconButton
                  disableRipple
                  size="small"
                  onClick={() =>
                    navigate(`${routes.ADMIN_CARD_ORDERS}/${params?.row?.id}`)
                  }
                >
                  <RemoveRedEyeOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Change Status" placement="top">
                <IconButton
                  disableRipple
                  onClick={() => handleOpen(params?.row)}
                  size="small"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download Excel File" placement="top">
                <IconButton
                  disableRipple
                  onClick={() => handleExportExcelFile(params?.row)}
                  size="small"
                >
                  <FileDownloadIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </>
        );
      },
    },
  ];
  return (
    <>
      <LoadingBackDrop loading={loading} />
      <PageTitle
        title="Print Card Orders"
        searchInput
        placeholder="Search..."
        search={search}
        handleSearchChange={handleSearchChange}
        isSearch={isSearch}
        handleClearSearch={handleClearSearch}
        handleSearchClick={handleSearchClick}
        isHandleChangeSearch={isHandleChangeSearch}
      />
      <DataGridListing
        columns={columns}
        fetchRecords={fetchOrders}
        queryName={queryNames.ORDER}
        search={searchedValue}
        isSearch={isSearch}
      />

      <Modal
        open={open}
        aria-describedby="modal-modal-description"
        size="small"
        onClose={handleClose}
      >
        <Box sx={styles.container}>
          <Box sx={styles.closeIconBtnWrapper}>
            <IconButton onClick={handleClose} sx={styles.closeIconBtn}>
              <Close />
            </IconButton>
          </Box>

          <SelectInput
            withasterisk={true}
            label="Change Status"
            value={selectedData.status || ""}
            onChange={handleChange}
            name="status"
            error={errors?.status ? true : false}
            errorMessage={errors?.status || ""}
            renderValue={(selected) => {
              if (selected?.length === 0) {
                return <span>Select Status</span>;
              }

              const found = changeStatusValues.find(
                (single) => single?.id === selected
              );

              return found?.name;
            }}
            displayEmpty
          >
            {changeStatusValues.map((statusValue, index) => {
              return (
                <MenuItem value={statusValue.id} key={index}>
                  {statusValue.name}
                </MenuItem>
              );
            })}
          </SelectInput>

          <Stack
            direction="row"
            sx={styles.btnsWrapper}
            justifyContent="center"
            spacing={2}
            textAlign="justify"
          >
            <Button
              variant="contained"
              sx={styles.cancelBtn}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              sx={styles.confirmBtn}
              onClick={handleSubmit}
              isLoading={mutation.isLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default CardOdersListing;
