import Listing from "../../../components/listing/DataGridListing";
import { ReactComponent as Edit } from "../../../assets/svg/Edit.svg";
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";
import PageTitle from "../../../components/common/PageTitle";
import routes from "../../../data/constants/routes";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import {
  PRODUCT_DELETE,
  PRODUCT_LISTING,
  PRODUCT_STATUS,
} from "../../../data/constants/apiRoutes";
import { get, destroy, put } from "../../../server";
import queryNames from "../../../data/constants/queryNames";
import { useNavigate } from "react-router-dom";
import BoxModal from "../../../components/modal/BoxModal";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import Overlay from "../../../components/overlay/Overlay";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { RemoveRedEyeOutlined } from "@mui/icons-material";

const ProductListing = () => {
  const [modal, setModal] = useState(false);
  const [product, setProduct] = useState();

  const [search, setSearch] = useState("");
  const [searchedValue, setSearchedValue] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [isHandleChangeSearch, setIsHandleChangeSearch] = useState(false);

  const navigate = useNavigate();
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setIsHandleChangeSearch(true);
    if (e.target.value.length < 1) {
      setIsHandleChangeSearch(false);
      setIsSearch(false);
      setSearchedValue("");
    }
  };

  const handleSearchClick = () => {
    setIsSearch(true);
    setSearchedValue(search);
  };

  const handleClearSearch = () => {
    setIsSearch(false);
    setSearch("");
    setSearchedValue("");
  };

  const columns = [
    {
      field: "name",
      headerName: "Product Name",
      flex: 1,
    },
    {
      field: "brand",
      headerName: "Brand",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price $",
      width: 100,
      valueGetter: (params) => "$" + params.row.price,
    },
    {
      field: "ownerName",
      headerName: "Owner Name",
      flex: 1,
      valueGetter: (params) =>
        `${params.row.created_by.first_name} ${params.row.created_by.last_name}`,
    },
    {
      field: "ownerEmail",
      headerName: "Owner Email",
      flex: 1,
      valueGetter: (params) => params.row.created_by.email,
    },
    {
      field: "product_views",
      headerName: "No of Clicks",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Typography textTransform="capitalize">{params.row.status}</Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0.5}>
            <Tooltip title="View Detail" placement="top">
              <IconButton
                disableRipple
                size="small"
                onClick={() =>
                  navigate(`${routes.ADMIN_PRODUCT}/${params?.row?.id}`)
                }
              >
                <RemoveRedEyeOutlined />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete" placement="top">
              <IconButton
                disableRipple
                onClick={() => {
                  setProduct(params.row);
                  setModal("delete");
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>

            {params.row.status !== "approved" && (
              <Tooltip title="Approve" placement="top">
                <IconButton
                  disableRipple
                  onClick={() => {
                    setProduct(params.row);
                    setModal("approve");
                  }}
                >
                  <CheckCircleOutlinedIcon sx={{ color: "green" }} />
                </IconButton>
              </Tooltip>
            )}

            {params.row.status !== "rejected" && (
              <Tooltip title="Reject" placement="top">
                <IconButton
                  disableRipple
                  onClick={() => {
                    setProduct(params.row);
                    setModal("reject");
                  }}
                >
                  <CancelOutlinedIcon sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
  ];

  const fetchProducts = async (pageNo, limit, searchParam) => {
    let url = new URL(PRODUCT_LISTING);
    if (searchParam.length) {
      url.searchParams.append("search", searchParam);
    }
    url.searchParams.append("page", pageNo + 1);
    url.searchParams.append("limit", limit);

    return await get(url.toString());
  };

  const handleInvalidate = () => {
    client.invalidateQueries(queryNames.PRODUCTS);
  };

  const handleClose = () => {
    setModal(false);
    setProduct(null);
    handleInvalidate();
  };

  const handleAction = async () => {
    if (modal === "delete") {
      const { status, message } = await destroy(
        `${PRODUCT_DELETE}/${product?.id}`
      );

      if (status) {
        enqueueSnackbar(message, { variant: "success" });
        handleClose();
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    } else {
      const { status, message } = await put(
        `${PRODUCT_STATUS}/${product?.id}`,
        {
          status: modal === "approve" ? "approved" : "rejected",
        }
      );
      if (status) {
        enqueueSnackbar(message, { variant: "success" });
        handleClose();
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  const mutation = useMutation(handleAction, {
    onSuccess: () => {
      client.invalidateQueries([queryNames.PRODUCTS]);
    },
  });

  const handleConfirm = (e) => {
    e.preventDefault();
    mutation.mutate();
  };

  return (
    <>
      <PageTitle
        title="Community eCommerce"
        searchInput
        placeholder="Search Products"
        search={search}
        setSearch={setSearch}
        isSearch={isSearch}
        handleClearSearch={handleClearSearch}
        handleSearchClick={handleSearchClick}
        handleSearchChange={handleSearchChange}
        isHandleChangeSearch={isHandleChangeSearch}
      />

      <Listing
        columns={columns}
        fetchRecords={fetchProducts}
        queryName={queryNames.PRODUCTS}
        search={searchedValue}
      />

      <BoxModal
        header="Are you sure?"
        title={`Do you really want to ${modal} ${product?.name}?`}
        open={modal === "approve" ? "confirm" : modal}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        confirmButton={
          modal === "delete"
            ? "Delete"
            : modal === "approve"
            ? "Approve"
            : "Reject"
        }
        confirmButtonColor={modal === "approve" ? "#D3A23B" : "#E53935"}
        isLoading={mutation.isLoading}
      />
    </>
  );
};

export default ProductListing;
