import { useState, useEffect, useMemo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Stack,
  InputAdornment,
  IconButton,
  Button,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import PageTitle from "../../../components/common/PageTitle";
import _ from "lodash";
import { useSnackbar } from "notistack";
import FormInput from "../../../components/forms/FormInput";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import routes from "../../../data/constants/routes";
import { SUBSCRIPTION_KEY } from "../../../data/constants/feature";
import AddBusinessCard from "../../../components/customer/AddBusinessCard";
import { styled } from "@mui/material/styles";
import PlaceHolderImg from "../../../assets/images/PlaceholderImage.jpg";
import RightBusinessDrawer from "../../../components/customer/RightBusinessDrawer";
import Logo from "../../../assets/images/LOGO.png";

import {
  CARDS_LISTING,
  CARDS_DELETE,
  CARDS_PRIMARY,
  CUSTOMER_CONTACT_CARD,
} from "../../../data/constants/apiRoutes";
import { destroy, get } from "../../../server";
import { MoreVertOutlined } from "@mui/icons-material";
import Loader from "../../../components/Loader";
import { useAtom } from "jotai";
import { loggedUserAtom, mobileViewAtom } from "../../../data/store";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import QrCodeModel from "../../../components/modal/QrCodeModel";
import CardModal from "../../../components/modal/CardModal";
import queryNames from "../../../data/constants/queryNames";
import { useQuery, useQueryClient } from "react-query";
import useFeature from "../../../hooks/useFeature";
// import { position } from "html2canvas/dist/types/css/property-descriptors/position";

const styles = {
  topWrapper: {
    flexDirection: { sm: "row", xs: "column" },
    alignItems: { sm: "center", xs: "flex-start" },
    justifyContent: "space-between",
    gap: 1.5,
    pb: 2,
  },
  addBtn: {
    whiteSpace: "nowrap",
    background: "#135099",
    borderRadius: 2.5,
    textTransform: "capitalize",
    width: 130,
    height: 50,
    "&:hover": {
      background: "#135099",
    },
  },
  gridWrapper: {
    display: "flex",
    justifyContent: { sm: "flex-start", xs: "center" },
    flexWrap: "wrap",
    alignItems: "center",
    gap: "30px",
    pt: 2,
  },

  card: {
    position: "relative",
    background: "#fff",
    borderRadius: 4,
    width: 230,
    height: 320,
    "& h3": {
      color: "#000",
      fontSize: 18,
      fontWeight: 600,
      textAlign: "center",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
      px: 2,
      mt: 5,
    },
    "& h4": {
      color: "#000",
      textAlign: "center",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
    },
    "& h6": {
      color: "#000",
      fontSize: 18,
      fontWeight: 600,
      textAlign: "center",
      p: "8px 8px 0 8px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
    },
    "&:hover": {
      "& .menuBtn": {
        opacity: 1,
      },
    },
  },
  menuBtn: {
    opacity: 1,
    position: "absolute",
    top: 15,
    right: 15,
    p: "5px",
    // background: "rgba(255, 255, 255, 0.3)",
    // backdropFilter: "blur(20px)",
    "& svg": {
      width: 20,
      height: 20,
    },
    "&:hover": {
      background: "rgba(255, 255, 255, 0.3)",
    },
  },
  coverImgWrapper: {
    height: 180,
    borderRadius: "16px 16px 0px 0px",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "16px 16px 0px 0px",
    },
  },
  coverImgSkeleton: {
    height: 180,
    borderRadius: "16px 16px 0px 0px",
    bgcolor: "#eee",
  },
  profileWrapper: {
    width: 80,
    height: 80,
    position: "absolute",
    top: 120,
    left: "50%",
    transform: "translateX(-50%)",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      border: "2px solid #fff",
      borderRadius: "50%",
    },
    "& svg": {
      width: "100%",
      height: 70,
    },
  },
  deleteModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    p: 4,
  },
  logoWrapper: {
    p: "12px 30px",
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  profile: {
    width: "100%",
    height: 180,
    objectFit: "cover",
  },
  profileSkeleton: {
    width: "100%",
    maxHeight: 130,
    objectFit: "contain",
  },
  logoWrapperTop: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "12px 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 60,
    width: "100%",
  },

  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // minHeight: "calc(100vh - 64px)",

    // width: "100%",
    mt: 5,
    "& h1": {
      fontSize: 24,
      color: "#000",
      fontWeight: 500,
    },
  },
  name: {
    color: "#1E1E1F",
    fontSize: 20,
    fontWeight: 500,
  },
};

const drawerWidth = 350;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, mobileView }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: mobileView ? 0 : -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const BuissnessCardsListing = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openQrModal, setOpenQrModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [cardModal, setCardModal] = useState(false);
  const [cards, setCards] = useState([]);
  const [currentCard, setCurrentCard] = useState({});
  const [filterText, setFilterText] = useState("");

  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [teamAnchorEl, setTeamAnchorEl] = useState(null);

  const menuOpen = Boolean(anchorEl);
  const teamMenuOpen = Boolean(teamAnchorEl);

  const [mobileView, setMobileView] = useAtom(mobileViewAtom);

  const cardId = sessionStorage.getItem("cardId");
  const { compareLimits, updateCount } = useFeature();
  const client = useQueryClient();

  useEffect(() => {
    if (cardId) {
      setCardModal(true);
    }
  }, [cardId, loggedUser]);

  const handleClick = (e, card, isTeamCard) => {
    setCurrentCard(card);
    if (isTeamCard) {
      setTeamAnchorEl(e.currentTarget);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };
  const handleCardClick = (card) => {
    if (open) {
      setCurrentCard(card);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTeamAnchorEl(null);
  };
  const handleQrOpen = () => {
    handleClose();
    setOpenQrModal(true);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
    handleClose();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setCurrentCard({});
  };

  const handleEditNavigation = () => {
    navigate(`${routes.CUSTOMER_EDIT_CARD}/${currentCard.id}`);
  };

  const handleDuplicate = async () => {
    handleClose();
    let res = compareLimits(SUBSCRIPTION_KEY.card);
    if (res) {
      navigate(`${routes.CUSTOMER_EDIT_CARD}/${currentCard.id}?duplicate=true`);
    }
  };

  const handleMakePrimary = async () => {
    const { status, message } = await get(`${CARDS_PRIMARY}/${currentCard.id}`);

    if (status) {
      handleInvalidate();
      enqueueSnackbar("Your selected card has been marked as primary.", { variant: "success" });
    }
    handleClose();
    setCurrentCard({});
  };

  const handleConfirmOpen = () => {
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };
  const handleOpenDelete = () => {
    handleClose();
    setOpenDeleteModal(true);
  };
  const handleCloseDelete = () => {
    setOpenDeleteModal(false);
    setOpen(false);
  };

  const handleInvalidate = () => {
    client.invalidateQueries(queryNames.CARD);
  };

  const handleConfirmDelete = async () => {
    const { status, message } = await destroy(
      `${CARDS_DELETE}/${currentCard.id}`
    );

    if (status) {
      handleInvalidate();
      // manage decrement card
      updateCount("delete");
      enqueueSnackbar(message, { variant: "success" });
    }
    handleClose();
    setCurrentCard({});
    handleCloseDelete();
  };

  const handleCardLimit = (path, type) => {
    
    let res = compareLimits(type);
    if (res) {
      navigate(path);
    } else {
      return false;
    }
  };

  const getListing = async () => {
    const { status, data } = await get(CARDS_LISTING);
    if (status) {
      const personalCards = data.personal?.map((item) => ({
        ...item,
        cardPhones: item.cardPhones?.map((phone) => ({
          phone: `${phone.countryCode}${phone.phone}}`,
          country_code: phone.countryCode,
        })),
        type: "personal",
      }));
      const teamCards = data.team?.map((item) => ({
        ...item,
        cardPhones: item.cardPhones?.map((phone) => ({
          phone: `${phone.countryCode}${phone.phone}}`,
          country_code: phone.countryCode,
        })),
        type: "team",
      }));
      setCards([...personalCards, ...teamCards]);
    }
  };

  const listingQuery = useQuery([queryNames.CARD], getListing, {
    refetchOnWindowFocus: false,
  });

  const getContactCard = async () => {
    const { status, message, data } = await get(CUSTOMER_CONTACT_CARD);

    if (status) {
      navigate(`${routes.CUSTOMER_EDIT_CARD}/${data.id}?contactCard=true`);
    } else {
      // enqueueSnackbar(message, { variant: "error" });
    }
  };

  const contactCardQuery = useQuery([queryNames.CONTACT_CARD], getContactCard, {
    enabled: cards?.length < 1,
    refetchOnWindowFocus: false,
  });

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const filteredCards = useMemo(() => {
    const filtered = cards.filter((item) =>
      `${item.fullName || ""} ${item.cardName || ""}`
        .toLowerCase()
        .includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, cards]);

  const hasPrimaryCard = useMemo(() => {
    const isHasPrimary = cards?.filter((item) => item?.is_primary == 1);

    if (isHasPrimary?.length > 0) {
      return true;
    } else {
      return false;
    }
  }, [cards]);

  if (
    listingQuery.isLoading ||
    listingQuery.isFetching ||
    contactCardQuery.isFetching
  ) {
    return <Loader />;
  }

  return (
    <>
      <Stack direction="row">
        <Main open={open} mobileView={mobileView}>
          <Box
            component="div"
            sx={{
              borderTop: "1px solid #EBEBEE",
              marginTop: "20px",
              py: "20px",
            }}
          >
            <Stack sx={styles.topWrapper}>
              <PageTitle title="KnocKnoc Cards" />
              <Stack direction="row" alignItems="center" spacing={2}>
                <FormInput
                  placeholder="Search..."
                  value={filterText}
                  onChange={handleFilter}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  sx={styles.addBtn}
                  onClick={() =>
                    handleCardLimit(
                      routes.CUSTOMER_CREATE_CARD,
                      SUBSCRIPTION_KEY.card
                    )
                  }
                >
                  Add Card
                </Button>
              </Stack>
            </Stack>

            {filteredCards.length > 0 ? (
              <>
                <Typography variant="h6" sx={styles.name} my={1}>
                  My Cards
                </Typography>
                <Box sx={styles.gridWrapper}>
                  <AddBusinessCard />
                  {filteredCards
                    ?.filter((card) => card.type === "personal")
                    ?.map((card) => (
                      <>
                        {card.templateNo === 2 || card.templateNo === 3 ? (
                          <Box
                            sx={styles.card}
                            key={card.id}
                            onClick={() => handleCardClick(card)}
                          >
                            {card.is_primary ? (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "15px",
                                  top: "15px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  display: "flex",
                                  backgroundColor: "#135099",
                                  width: "25px",
                                  height: "25px",
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                                  borderRadius: "100px",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "InstrumentSans-Bold",
                                    color: "white",
                                    fontSize: "12px",
                                  }}
                                >
                                  P
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            {card.backgroundImage ? (
                              <Box sx={styles.coverImgWrapper}>
                                <Box
                                  component="img"
                                  src={`${
                                    card.backgroundImage.includes("amazonaws")
                                      ? card.backgroundImage
                                      : card.backgroundImage
                                  }`}
                                />
                              </Box>
                            ) : (
                              <Box sx={styles.coverImgSkeleton}></Box>
                            )}

                            <Box sx={styles.logoWrapperTop}>
                              <Box
                                component="img"
                                src={card?.logoImage}
                                sx={styles.logo}
                              />
                            </Box>

                            <Box sx={styles.profileWrapper}>
                              {card.profileImage ? (
                                <Box component="img" src={card.profileImage} />
                              ) : (
                                <Box
                                  component="img"
                                  src={PlaceHolderImg}
                                  alt="Placeholder-img"
                                />
                              )}
                            </Box>
                            <IconButton
                              sx={styles.menuBtn}
                              onClick={(e) => handleClick(e, card, false)}
                              aria-controls={menuOpen ? "menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={menuOpen ? "true" : undefined}
                              className="menuBtn"
                            >
                              <MoreVertOutlined />
                            </IconButton>

                            <Typography component="h3">
                              {card.cardName ? card.cardName : card.fullName}
                            </Typography>
                            {card.cardName ? null : (
                              <Typography component="h4">Draft </Typography>
                            )}
                          </Box>
                        ) : (
                          <Box
                            sx={styles.card}
                            key={card.id}
                            onClick={() => handleCardClick(card)}
                          >
                            {card.is_primary ? (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "15px",
                                  top: "15px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  display: "flex",
                                  backgroundColor: "#135099",
                                  width: "25px",
                                  height: "25px",
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                                  borderRadius: "100px",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "InstrumentSans-Bold",
                                    color: "white",
                                    fontSize: "12px",
                                  }}
                                >
                                  P
                                </p>
                              </div>
                            ) : (
                              ""
                            )}

                            <Box sx={styles.logoWrapper}>
                              <Box
                                component="img"
                                src={card?.logoImage}
                                sx={styles.logo}
                              />
                            </Box>
                            {card.profileImage ? (
                              <Box
                                component="img"
                                src={card.profileImage}
                                sx={styles.profile}
                              />
                            ) : (
                              <Box
                                component="img"
                                src={PlaceHolderImg}
                                alt="Placeholder-img"
                                sx={styles.profileSkeleton}
                              />
                            )}
                            <Typography component="h6">
                              {card.cardName ? card.cardName : card.fullName}
                            </Typography>
                            {card.cardName ? null : (
                              <Typography component="h4">Draft </Typography>
                            )}
                            <IconButton
                              sx={styles.menuBtn}
                              className="menuBtn"
                              onClick={(e) => handleClick(e, card, false)}
                              aria-controls={menuOpen ? "menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={menuOpen ? "true" : undefined}
                            >
                              <MoreVertOutlined />
                            </IconButton>
                          </Box>
                        )}

                        <Menu
                          anchorEl={anchorEl}
                          id="menu"
                          open={menuOpen}
                          onClose={handleClose}
                          // onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              mt: 1,
                              borderRadius: 5,
                              width: 200,
                              boxShadow: 1,
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem onClick={handleDrawerOpen}>
                            View Card
                          </MenuItem>
                          <MenuItem onClick={handleEditNavigation}>
                            Edit Card
                          </MenuItem>
                          {!currentCard.is_primary && (
                            <MenuItem onClick={()=> hasPrimaryCard ? handleConfirmOpen() : handleMakePrimary()}>
                              {"Make Primary"}
                            </MenuItem>
                          )}
                          <MenuItem onClick={handleQrOpen}>
                            Download QR
                          </MenuItem>
                          <MenuItem onClick={handleDuplicate}>
                            Duplicate Card
                          </MenuItem>
                          <MenuItem onClick={handleOpenDelete}>
                            Delete Card
                          </MenuItem>
                          {/* <MenuItem
                            onClick={() =>
                              navigate(
                                `${routes.CUSTOMER_BUSINESS_CARD_ORDER}/${currentCard.id}`
                              )
                            }
                          >
                            Order Card Print
                          </MenuItem> */}
                        </Menu>
                      </>
                    ))}
                </Box>

                {filteredCards?.filter((card) => card.type === "team").length >
                  0 && (
                  <Typography variant="h6" sx={styles.name} mb={1} mt={2}>
                    Team Cards
                  </Typography>
                )}
                <Box sx={styles.gridWrapper}>
                  {filteredCards
                    ?.filter((card) => card.type === "team")
                    ?.map((card) => (
                      <>
                        {card.templateNo === 2 || card.templateNo === 3 ? (
                          <Box
                            sx={styles.card}
                            key={card.id}
                            onClick={() => handleCardClick(card)}
                          >
                            {card.is_primary ? (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "15px",
                                  top: "15px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  display: "flex",
                                  backgroundColor: "#135099",
                                  width: "25px",
                                  height: "25px",
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                                  borderRadius: "100px",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "InstrumentSans-Bold",
                                    color: "white",
                                    fontSize: "12px",
                                  }}
                                >
                                  P
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            {card.backgroundImage ? (
                              <Box sx={styles.coverImgWrapper}>
                                <Box
                                  component="img"
                                  src={`${
                                    card.backgroundImage.includes("amazonaws")
                                      ? card.backgroundImage
                                      : card.backgroundImage
                                  }`}
                                />
                              </Box>
                            ) : (
                              <Box sx={styles.coverImgSkeleton}></Box>
                            )}

                            <Box sx={styles.logoWrapperTop}>
                              <Box
                                component="img"
                                src={card?.logoImage}
                                sx={styles.logo}
                              />
                            </Box>

                            <Box sx={styles.profileWrapper}>
                              {card.profileImage ? (
                                <Box component="img" src={card.profileImage} />
                              ) : (
                                <Box
                                  component="img"
                                  src={PlaceHolderImg}
                                  alt="Placeholder-img"
                                />
                              )}
                            </Box>
                            <IconButton
                              sx={styles.menuBtn}
                              onClick={(e) => handleClick(e, card, true)}
                              aria-controls={teamMenuOpen ? "menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={teamMenuOpen ? "true" : undefined}
                              className="menuBtn"
                            >
                              <MoreVertOutlined />
                            </IconButton>

                            <Typography component="h3">
                              {card.cardName ? card.cardName : card.fullName}
                            </Typography>
                            {card.cardName ? null : (
                              <Typography component="h4">Draft </Typography>
                            )}
                          </Box>
                        ) : (
                          <Box
                            sx={styles.card}
                            key={card.id}
                            onClick={() => handleCardClick(card)}
                          >
                            {card.is_primary ? (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "15px",
                                  top: "15px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  display: "flex",
                                  backgroundColor: "#135099",
                                  width: "25px",
                                  height: "25px",
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                                  borderRadius: "100px",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "InstrumentSans-Bold",
                                    color: "white",
                                    fontSize: "12px",
                                  }}
                                >
                                  P
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            <Box sx={styles.logoWrapper}>
                              <Box
                                component="img"
                                src={card?.logoImage}
                                sx={styles.logo}
                              />
                            </Box>
                            {card.profileImage ? (
                              <Box
                                component="img"
                                src={card.profileImage}
                                sx={styles.profile}
                              />
                            ) : (
                              <Box
                                component="img"
                                src={PlaceHolderImg}
                                alt="Placeholder-img"
                                sx={styles.profileSkeleton}
                              />
                            )}
                            <Typography component="h6">
                              {card.cardName ? card.cardName : card.fullName}
                            </Typography>
                            {card.cardName ? null : (
                              <Typography component="h4">Draft </Typography>
                            )}
                            <IconButton
                              sx={styles.menuBtn}
                              className="menuBtn"
                              onClick={(e) => handleClick(e, card, true)}
                              aria-controls={
                                teamMenuOpen ? "teamMenu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={teamMenuOpen ? "true" : undefined}
                            >
                              <MoreVertOutlined />
                            </IconButton>
                          </Box>
                        )}
                      </>
                    ))}
                </Box>
              </>
            ) : (
              <Box sx={styles.noData}>
                <Typography component="h1">No record found</Typography>
              </Box>
            )}

            <Menu
              anchorEl={teamAnchorEl}
              id="teamMenu"
              open={teamMenuOpen}
              onClose={handleClose}
              // onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  mt: 1,
                  borderRadius: 5,
                  width: 200,
                  boxShadow: 1,
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleDrawerOpen}>View Card</MenuItem>
              <MenuItem onClick={handleEditNavigation}>Edit Card</MenuItem>
              {!currentCard.is_primary && (
                <MenuItem onClick={()=> hasPrimaryCard ? handleConfirmOpen() : handleMakePrimary()}>
                  {"Make Primary"}
                </MenuItem>
              )}
              <MenuItem onClick={handleQrOpen}>Download QR</MenuItem>

              <MenuItem onClick={handleDuplicate}>Duplicate Card</MenuItem>
              <MenuItem onClick={handleOpenDelete}>Delete Card</MenuItem>
            </Menu>
          </Box>
        </Main>

        <RightBusinessDrawer
          handleOpenDelete={handleOpenDelete}
          open={open}
          inViewMode={true}
          handleDrawerClose={handleDrawerClose}
          {...currentCard}
        />
      </Stack>
      <ConfirmationModal
        header="Are you sure?"
        title={`Do you really want to delete this record. This process cannot be undone.`}
        confirmButton="Delete"
        confirmButtonColor="#E53935"
        open={openDeleteModal}
        handleClose={handleCloseDelete}
        handleConfirm={handleConfirmDelete}
      />
      <QrCodeModel
        open={openQrModal}
        handleClose={() => setOpenQrModal(false)}
        uuid={currentCard.uuid}
        personName={currentCard.fullName}
        qrCodeUrl={currentCard?.qrCodeUrl}
        shareURL={currentCard?.shareURL}
      />

      <CardModal
        cardModal={cardModal}
        setCardModal={setCardModal}
        cards={cards}
      />

      {/* <Button variant="contained" color="primary" onClick={handleConfirmOpen}>
        Make Primary
      </Button> */}

      {/* Confirmation dialog */}
      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>

          <DialogContentText>
            
            You can mark only one card as primary. If you set this card as
            primary, the previous one will be deselected.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleMakePrimary();
              handleConfirmClose();
            }}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BuissnessCardsListing;
